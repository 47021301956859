import React, { SyntheticEvent, useState } from 'react';
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom';
import { Collapse, DropdownItem, DropdownMenu, DropdownToggle, Nav, Navbar, NavbarBrand, NavbarToggler, NavItem, UncontrolledDropdown } from 'reactstrap';
import Account from './Account/Account';
import ChangePassword from './Account/ChangePassword';
import { useCurrentUserContext } from './Shared/CurrentUserContext';


export default function Menu() {
    const location = useLocation()
    const { isLoggedIn, currentUser, logout } = useCurrentUserContext()
    const [navbarOpen, setNavbarOpen] = useState(false)
    const [accountModalOpen, setAccountModalOpen] = useState(false)
    const [passwordModalOpen, setPasswordModalOpen] = useState(false)
    const navigate = useNavigate()

    const handleLogout = async (event: SyntheticEvent) => {
        event.preventDefault()
        await logout()
        navigate('/login')
    }

    return (
        <>
            <Navbar className='navbar fixed-top navbar-expand-md navbar-light navbar-bg-flexinets'>
                <NavbarBrand tag={Link} to={'/'}><img src='/Content/img/flexible-networks-nordic_logo-genomskinlig-300x77.png' alt='flexinets logo' /></NavbarBrand>
                {isLoggedIn && location.pathname !== '/signup' &&   // a bit ugly, but oh well
                    <>
                        <NavbarToggler onClick={() => setNavbarOpen(old => !old)} />
                        <Collapse isOpen={navbarOpen} navbar>
                            <Nav className='me-auto' navbar>
                                <NavItem><NavLink to='/users' className={({ isActive }) => isActive ? "menuactive nav-link" : "nav-link"}> Users</NavLink></NavItem>
                                <NavItem><NavLink to='/subscription' className={({ isActive }) => isActive ? "menuactive nav-link" : "nav-link"}>Subscription</NavLink></NavItem>
                                <NavItem><NavLink to='/servicealerts' className={({ isActive }) => isActive ? "menuactive nav-link" : "nav-link"}>Service alerts</NavLink></NavItem>
                                <NavItem><NavLink to='/faq' className={({ isActive }) => isActive ? "menuactive nav-link" : "nav-link"}>FAQ</NavLink></NavItem>
                                {currentUser?.roles?.includes('GlobalAdmin') && <NavItem><NavLink to='/shoptokens' className={({ isActive }) => isActive ? "menuactive nav-link" : "nav-link"}>Shop tokens</NavLink></NavItem>}
                                {currentUser?.roles?.includes('GlobalAdmin') && <NavItem><NavLink to='/admins' className={({ isActive }) => isActive ? "menuactive nav-link" : "nav-link"}>Admins</NavLink></NavItem>}
                            </Nav>
                            <Nav navbar>
                                <UncontrolledDropdown nav inNavbar>
                                    <DropdownToggle nav caret>{currentUser?.emailAddress}</DropdownToggle>
                                    <DropdownMenu end>
                                        <DropdownItem tag='a' href='' onClick={(e) => { e.preventDefault(); setAccountModalOpen(true); }}><i className='fas fa-user' /> My Account</DropdownItem>
                                        <DropdownItem tag='a' href='' onClick={(e) => { e.preventDefault(); setPasswordModalOpen(true); }}><i className='fas fa-key' /> Change Password</DropdownItem>
                                        <DropdownItem divider />
                                        <DropdownItem tag='a' href='#logout' onClick={handleLogout}><i className='fas fa-sign-out-alt' /> Log off</DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                            </Nav>
                        </Collapse>
                    </>
                }
            </Navbar>

            {accountModalOpen && <Account onClosed={() => setAccountModalOpen(false)} />}
            {passwordModalOpen && <ChangePassword onClosed={() => setPasswordModalOpen(false)} />}
        </>
    )
}
