import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { NewsItemModel } from '../apiclients/ipassportalapiclient';
import { useApiClients } from '../Shared/ApiClientsContext';
import { LoadingSpinner } from '../Shared/components';

export default function ServiceAlerts() {
    document.title = 'Service Alerts'

    const { ipassApiClient } = useApiClients()
    const [loading, setLoading] = useState(false)
    const [serviceAlerts, setServiceAlerts] = useState<NewsItemModel[]>([])

    useEffect(() => {
        setLoading(true)
        ipassApiClient.news.serviceAlerts().then(result => {
            setServiceAlerts(result)
            setLoading(false)
        })
    }, [ipassApiClient.news])

    return (
        <div className='container'>
            <h3>Service alerts <i className='fas fa-exclamation-triangle' /></h3>

            {loading
                ? <LoadingSpinner>Getting service alerts...</LoadingSpinner>
                : <div className='card mb-3'>
                    <div className='card-body'>
                        <div className='news'>
                            {serviceAlerts.map((item, index) =>
                                <article key={index}>
                                    <span className='newsdate'>{moment(item.createdOn).format('MMMM Do')}</span>
                                    <header>{item.title}</header>
                                    <p dangerouslySetInnerHTML={{ __html: item.text }} />
                                    <a href={item.url}>Read more...</a>
                                </article>,
                            )}
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}
