import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import React, { FormEvent, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import { isApiError, useApiClients } from '../Shared/ApiClientsContext';
import { ButtonLoading } from '../Shared/components';

type Params = {
    adminId: string
}

type Props = {
    onClosed: (adminId?: number) => void
}

export default function AdminDeleteModal(props: Props) {
    document.title = 'Delete admin'

    const { adminId } = useParams<Params>()
    const [loading, setLoading] = useState(false)
    const { ipassApiClient } = useApiClients()
    const appInsights = useAppInsightsContext()

    const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        if (adminId) {
            setLoading(true)
            ipassApiClient.admins.deleteAdmin(parseInt(adminId, 10)).then(() => {
                toast.info('Admin deleted');
                props.onClosed(parseInt(adminId, 10))
            }).catch(e => {
                if (isApiError(e)) {
                    appInsights.trackException({ properties: { ...e } })
                }
                toast.error(`Something went wrong: ${e}`);
            }).finally(() => {
                setLoading(false)
            })
        }
    }


    return (
        <Modal onClosed={props.onClosed} isOpen={true}>
            <form onSubmit={handleSubmit} noValidate>
                <div className='modal-content'>
                    <ModalBody>
                        <h5>Are you sure you want to delete admin?</h5>
                    </ModalBody>
                    <ModalFooter>
                        <ButtonLoading className='btn btn-danger' loading={loading} type='submit'>Delete</ButtonLoading> <button type='button' className='btn btn-default' onClick={() => props.onClosed()}>Cancel</button>
                    </ModalFooter>
                </div>
            </form>
        </Modal>
    )
}
