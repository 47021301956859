import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import React, { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { StripeCardResult } from '../apiclients/ipassportalapiclient';
import { isApiError, useApiClients } from '../Shared/ApiClientsContext';
import { LoadingSpinner } from '../Shared/components';
import Cards from './Cards';
import Charges from './Charges';
import Subscriptions from './subscriptions';

export default function Subscription() {
    const { ipassApiClient } = useApiClients()
    const [loading, setLoading] = useState(false)
    const [stripe, setStripe] = useState<StripeCardResult | null>(null)
    const appInsights = useAppInsightsContext()
    document.title = 'Subscription';

    const getSubscription = useCallback(() => {
        setLoading(true)
        ipassApiClient.stripe.stripeCard().then(result => {
            setStripe(result)
        }).catch(e => {
            if (isApiError(e)) {
                appInsights.trackException({ properties: { ...e } })
                toast.error(`Something went wrong: ${e.message}`)
            }
        }).finally(() => {
            setLoading(false)
        })
    }, [ipassApiClient.stripe, appInsights])

    useEffect(() => {
        getSubscription()
    }, [getSubscription])

    return (
        <div className='container'>
            <h3>Manage subscription</h3>

            {loading && <LoadingSpinner>Getting payment and subscription information...</LoadingSpinner>}

            {stripe !== null &&
                <div className='row'>
                    <div className='col-sm'>
                        <Subscriptions onSubscriptionChanged={getSubscription} subscriptions={stripe.subscriptions} nextInvoice={stripe.nextInvoice} />
                        <Cards onCardChanged={getSubscription} cards={stripe.cards} />
                    </div>
                    <div className='col-sm'>
                        <Charges charges={stripe.charges} />
                    </div>
                </div>
            }
        </div>
    )
}
