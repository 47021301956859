/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CompleteSignupModel } from '../models/CompleteSignupModel';
import type { SignupModel } from '../models/SignupModel';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class SubscriptionService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @param requestBody
     * @returns string Success
     * @throws ApiError
     */
    public createSubscription(
        requestBody?: SignupModel,
    ): CancelablePromise<string> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/createsubscription',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody
     * @returns any Success
     * @throws ApiError
     */
    public completeSubscription(
        requestBody?: CompleteSignupModel,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/completesubscription',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
