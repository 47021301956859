import React, { createContext, useContext } from "react"
import { ApiError } from "../apiclients/authenticationapiclient"
import { ipassapiclient } from "../apiclients/ipassportalapiclient"
import { AppConfig } from "../appConfig"
import { useCurrentUserContext } from "./CurrentUserContext"

type ApiClientsContextModel = {
    ipassApiClient: Readonly<ipassapiclient>,
    anonymousIpassApiClient: Readonly<ipassapiclient>,
}

const ApiClientsContext = createContext<ApiClientsContextModel | undefined>(undefined)

export function isApiError(arg: any): arg is ApiError {
    return arg.name === 'ApiError'
}

export function ApiClientsContextProvider({ children }: { children: React.ReactNode }) {
    const { getRefreshedAccessToken } = useCurrentUserContext()

    const ipassApiClient = new ipassapiclient({ BASE: AppConfig.REACT_APP_BACKEND_BASE_URL, TOKEN: getRefreshedAccessToken })
    const anonymousIpassApiClient = new ipassapiclient({ BASE: AppConfig.REACT_APP_BACKEND_BASE_URL })

    return (
        <ApiClientsContext.Provider value={{
            ipassApiClient: ipassApiClient,
            anonymousIpassApiClient: anonymousIpassApiClient,
        }}>
            {children}
        </ApiClientsContext.Provider>
    )
}

export const useApiClients = () => {
    const context = useContext(ApiClientsContext)
    if (context) {
        return context
    }

    throw Error('Context undefined? Forgot a provider somewhere?')
}