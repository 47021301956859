import React, { useState } from 'react';
import { useApiClients } from '../Shared/ApiClientsContext';
import { ButtonLoading } from '../Shared/components';


export default function ShopToken() {
    document.title = 'Shop tokens'

    const apiClients = useApiClients()
    const [loading, setLoading] = useState(false)
    const [token, setToken] = useState('')

    const generateToken = async () => {
        setLoading(true)
        const tokenResponse = await apiClients.ipassApiClient.stripe.getCode()
        setLoading(false)
        setToken(tokenResponse.token ?? '')
    }

    return (
        <div className='container'>
            <h3>Generate shop token</h3>
            <ButtonLoading className='btn btn-primary' loading={loading} onClick={() => generateToken()} type='submit'>Generate token</ButtonLoading>

            {token && <p>Token: <strong>{token}</strong></p>}
        </div>
    )
}
