import React, { useEffect, useState } from "react";
import { Link, Route, Routes, useNavigate } from "react-router-dom";
import { AdminModel } from "../apiclients/ipassportalapiclient";
import { useApiClients } from "../Shared/ApiClientsContext";
import { AsyncActionState } from "../Shared/AsyncActionState";
import { LoadingSpinner } from "../Shared/components";
import AdminDeleteModal from "./AdminDeleteModal";
import AdminDetailModal from "./AdminDetail";
import AdminListRow from "./AdminListRow";

const searchFilter = (admin: AdminModel, search: string): boolean =>
    search === ''
    || admin.firstname.toLowerCase().includes(search)
    || admin.lastname.toLowerCase().includes(search)
    || admin.email.toLowerCase().includes(search)

export default function AdminList() {
    const { ipassApiClient } = useApiClients()
    const [admins, setAdmins] = useState<AsyncActionState<readonly AdminModel[]>>({ data: [], state: 'init' })
    const [searchString, setSearchString] = useState('')
    const navigate = useNavigate()

    const [currentPage, setCurrentPage] = useState(1)
    const itemsPerPage = 50

    const startIndex = (currentPage - 1) * itemsPerPage;
    const filteredData = admins.data.filter(o => searchFilter(o, searchString.toLowerCase()))
    const currentPageData = filteredData.slice(startIndex, startIndex + itemsPerPage)
    const pages = Array.from({ length: Math.ceil(filteredData.length / itemsPerPage) })

    const onModified = async (admin: AdminModel | undefined) => {
        navigate('/admins')
        document.title = 'Admins'

        if (admin) {
            setAdmins(o => ({ ...o, data: [admin, ...o.data] }))
        }
    }

    useEffect(() => {
        setAdmins(o => ({ ...o, state: 'pending' }))
        ipassApiClient.admins.listAdmins().then(list => {
            setAdmins({ data: list, state: 'success' })
        })
    }, [ipassApiClient.admins])

    const onDeleted = (adminId?: number | undefined) => {
        navigate('/admins')
        document.title = 'Admins'

        if (adminId) {
            setAdmins(o => ({ ...o, data: o.data.filter(o => o.adminId !== adminId) }))
        }
    }

    return (
        <div className='container'>
            <h3>Admins</h3>
            {admins.state === 'pending' && <LoadingSpinner>Getting admins...</LoadingSpinner>}

            {admins.state === 'success' &&
                <div className='card mb-3'>
                    <div className='card-body'>
                        <div className='float-start'>
                            <Link to='/admins/create' className='btn btn-primary'><i className='fas fa-plus' /> Add admin</Link>{' '}
                        </div>
                        <div className='float-end'>
                            <span className='input-group'>
                                <input name='Search' type='search' value={searchString} onChange={e => { setCurrentPage(1); setSearchString(e.target.value) }} className='form-control' placeholder='Search admins...' />
                            </span>
                        </div>

                        <table className='table table-hover users-table mt-3'>
                            <thead>
                                <tr className='d-none d-md-table-row'>
                                    <td>
                                        <div className='row'>
                                            <strong className='col-md-5'>Email</strong>
                                            <strong className='col-md-5'>Name</strong>
                                        </div>
                                    </td>
                                    <td className='wrapcolumn' />
                                </tr>
                            </thead>
                            <tbody>
                                {currentPageData.map(admin => <AdminListRow key={admin.adminId} admin={admin} />)}
                            </tbody>
                        </table>
                    </div>
                </div>
            }

            <nav aria-label="Page navigation example">
                <ul className="pagination">
                    {pages.map((_, index) => (
                        <li className="page-item">
                            <a className="page-link" href={`#page=${index + 1}`} key={index + 1} onClick={() => { window.scrollTo(0, 0); setCurrentPage(index + 1) }}>{index + 1}</a>
                        </li>
                    ))}
                </ul>
            </nav>

            <Routes>
                <Route path='create' element={<AdminDetailModal onClosed={onModified} />} />
                <Route path='edit/:adminId' element={<AdminDetailModal onClosed={onModified} />} />
                <Route path='delete/:adminId' element={<AdminDeleteModal onClosed={onDeleted} />} />
            </Routes>
        </div>
    )
}