import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { isApiError } from '../Shared/ApiClientsContext';
import { ButtonLoading } from '../Shared/components';
import { useCurrentUserContext } from '../Shared/CurrentUserContext';
import Input from '../Signup/TextInput';


export default function BeginPasswordReset(props: { onClosed: () => void }) {
    document.title = 'Begin Password Reset'

    const [loading, setLoading] = useState(false)
    const { beginReset } = useCurrentUserContext()
    const navigate = useNavigate()
    const appInsights = useAppInsightsContext()

    const { register, handleSubmit, formState: { errors } } = useForm<{ emailAddress: string }>({ mode: 'all', defaultValues: {} })


    const handleFormSubmit = handleSubmit(async (formData, errors) => {
        if (!errors) {
            setLoading(true)

            try {
                await beginReset(formData.emailAddress, 'https://wifi.flexinets.se/reset/')
                toast.success(`If your email address is registered, a reset email has been sent to ${formData.emailAddress}`)
                onClosed()
            } catch (e) {
                if (isApiError(e)) {
                    appInsights.trackException({ properties: { ...e } })
                }
                toast.error(`Something went wrong ${e}`);
            }

            setLoading(false)
        }
    })

    const onClosed = () => navigate('/login');

    return (
        <Modal onClosed={onClosed} isOpen={true}>
            <form onSubmit={e => { e.preventDefault(); handleFormSubmit() }}>
                <div className='modal-content'>
                    <ModalHeader>Reset password</ModalHeader>
                    <ModalBody>
                        <p>
                            Enter your email address below and click Reset password.<br />
                            You will shortly receive an email containing a link to reset your password.
                        </p>
                        <Input type='email' label='Email' name='emailAddress' placeholder='email@example.com' register={register} errors={errors} required />
                    </ModalBody>
                    <ModalFooter>
                        <ButtonLoading className='btn btn-primary' onSubmit={e => { e.preventDefault(); handleFormSubmit() }} disabled={loading} loading={loading} type='submit'>Start password reset</ButtonLoading> <button type='button' className='btn btn-default' onClick={onClosed}>Cancel</button>
                    </ModalFooter>
                </div>
            </form>
        </Modal>
    )
}
