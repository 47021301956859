import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { useCallback, useEffect, useRef, useState } from 'react';
import { CalculateResponse, CountryModel, CouponModel, ProductsModel, SignupModel } from '../apiclients/ipassportalapiclient';
import { AppConfig } from '../appConfig';
import { isApiError, useApiClients } from '../Shared/ApiClientsContext';
import { ButtonLoading, LoadingSpinner } from '../Shared/components';
import { useCurrentUserContext } from '../Shared/CurrentUserContext';
import FeaturesFooter from '../Shared/FeaturesFooter';
import CheckoutSummary from './CheckoutSummary';
import CountrySelect from './CountrySelect';
import TermsAndConditions from './TermsAndConditions';
import { useForm } from 'react-hook-form';
import Input, { resolveErrorMessage } from './TextInput';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe, StripeElementsOptions } from '@stripe/stripe-js';
import Checkout from './Checkout';
import SubscriptionTypeSummary from './SignupTypeSummary';
import debounce from 'lodash.debounce';
import { AsyncActionState } from '../Shared/AsyncActionState';
import { useSearchParams } from 'react-router-dom';
import './Signup.scss'

const stripePromise = loadStripe(AppConfig.REACT_APP_STRIPE_PK)


export default function Signup() {

    document.title = 'Signup'
    window.location.replace("https://www.flexinets.eu/globalwifien/");

    const { register, setValue, handleSubmit, formState: { errors }, getValues, watch } = useForm<SignupModel>({
        mode: 'all', defaultValues: {
            licenseCount: 1,
            signupType: 'Admin',
            country: '',
        }
    })

    const { licenseCount, vatnumber, country } = watch()
    const [checkingVatNumber, setCheckingVatNumber] = useState<boolean>(false)
    const [isEuCountry, setIsEuCountry] = useState<boolean>(false)
    const [processingPayment, setProcessingPayment] = useState<boolean>(false)
    const [checkingPromoCode, setCheckingPromoCode] = useState<boolean>(false)
    const [coupon, setCoupon] = useState<CouponModel | null>(null)
    const [modalOpen, setModalOpen] = useState<boolean>(false)
    const [selectedProduct, setSelectedProduct] = useState<AsyncActionState<ProductsModel | null>>({ state: 'init', data: null })
    const [calculation, setCalculation] = useState<CalculateResponse | undefined>(undefined)
    const [stripeElementOptions, setStripeElementOptions] = useState<StripeElementsOptions>({
        mode: 'subscription',
        amount: 0,
        currency: 'eur',
    })

    const appInsights = useAppInsightsContext()
    const { anonymousIpassApiClient } = useApiClients()

    const [searchParams] = useSearchParams()

    const { checkEmailAvailability, currentUser, isLoggedIn } = useCurrentUserContext()

    const validateCoupon = useCallback(async (value: string | null) => {
        if (value) {
            setCheckingPromoCode(true)
            try {
                const response = await anonymousIpassApiClient.stripe.validateCoupon(value)
                setCoupon(response.coupon ?? null)
                setValue('couponId', response.coupon?.valid ? response.coupon.id : '')
            } catch (e) {
                if (isApiError(e)) {
                    appInsights.trackException({ properties: { ...e } })
                }
            }

            setCheckingPromoCode(false)
        }
    }, [anonymousIpassApiClient.stripe, setValue, appInsights])


    const updatePrice = useCallback(async () => {
        if (selectedProduct.data && country) {
            try {
                const calculationResult = await anonymousIpassApiClient.stripe.calculate({
                    countryCode: country,
                    price: selectedProduct.data.price,
                    quantity: licenseCount,
                    vatId: vatnumber,
                    discount: coupon?.amountOff,
                })

                setStripeElementOptions({ amount: calculationResult.amountTotal, mode: 'subscription', currency: selectedProduct.data.currency })
                setCalculation(calculationResult)
            } catch (e) {
                if (isApiError(e)) {
                    appInsights.trackException({ properties: { ...e } })
                }
            }
        }
    }, [anonymousIpassApiClient.stripe, appInsights, country, licenseCount, selectedProduct.data, vatnumber, coupon?.amountOff])

    useEffect(() => {
        updatePrice()
    }, [coupon, licenseCount, selectedProduct.data, country, vatnumber, appInsights, updatePrice])

    useEffect(() => {
        if (selectedProduct.state === 'init') {
            setSelectedProduct({ state: 'pending', data: null })
            anonymousIpassApiClient.stripe.products().then(async response => {
                const product = response[0]
                setSelectedProduct({ state: 'success', data: product })
                setValue('product', product.id)
                validateCoupon(searchParams.get('promocode'))
            }).catch(e => {
                if (isApiError(e)) {
                    appInsights.trackException({ properties: { ...e } })
                }

                setSelectedProduct({ state: 'error', data: null })
                appInsights.trackEvent({ name: 'invalid_shoptoken' })
                window.location.replace('https://www.flexinets.eu/wifi-offer-contact-en/');
            })
        }
    }, [anonymousIpassApiClient.stripe, selectedProduct.state, validateCoupon, appInsights, setValue, searchParams])


    const validateEmail = useRef(debounce(async (value: string) => (await checkEmailAvailability(value)) ? undefined : 'This email is already in use. If this is your account, sign in', 700, { leading: true })).current

    const checkVatNumber = useRef(debounce(async (value: string) => {
        setCheckingVatNumber(true)
        setValue('vatnumber', value)

        updatePrice().finally(() => {
            setCheckingVatNumber(false)
        })
    }, 700, { leading: true })).current


    const handleCountryChanged = useCallback((country: CountryModel) => {
        setValue('country', country.code)
        setIsEuCountry(country.isEu)

        if (!country.isEu) {
            setValue('vatnumber', '')
        }
    }, [setValue])

    return (
        <>
            <div className='col-md-10 offset-md-1 col-lg-8 offset-lg-2'>
                <div className='card mb-3'>
                    <section style={{ display: processingPayment ? 'none' : 'initial' }}>
                        <form>
                            <div className='card-body m-2'>
                                <SubscriptionTypeSummary coupon={coupon} selectedProduct={selectedProduct.data} signupType={getValues('signupType')} />
                            </div>

                            <div className='card-body m-2'>
                                <h3 className='text-center m-2'>Create account</h3>

                                <div className='row'>
                                    {!isLoggedIn ?
                                        <>
                                            <div className='col-md-6'>
                                                <div className='form-group required'>
                                                    <label htmlFor='name'>Email</label>
                                                    <input type='email' autoComplete='username' placeholder='email@example.com' className={errors.email ? 'form-control is-invalid' : 'form-control'}  {...register('email', { validate: validateEmail, required: true })} />
                                                    <div className="invalid-feedback">{resolveErrorMessage(errors.email)}</div>
                                                </div>
                                            </div>
                                            <div className='col-md-6'>
                                                <Input type='password' label='Password' autoComplete='new-password' placeholder='Create a password' name='password' register={register} errors={errors} required />
                                            </div>
                                        </>
                                        : <div className='col-md-6'>Signed in as: {currentUser?.emailAddress}</div>
                                    }
                                </div>

                                <hr />
                                <h3 className='text-center m-2'>Billing information</h3>


                                <div className='row'>
                                    <div className='col-md-6'>
                                        <Input type='text' label='Name / Company name' name='name' register={register} errors={errors} required />
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col-md-6'>
                                        <div className='form-group required'>
                                            <label htmlFor='country'>Country</label>
                                            <CountrySelect label='Country' name='country' register={register} required={true} errors={errors} onCountryChanged={handleCountryChanged} />
                                            <div className="invalid-feedback">{resolveErrorMessage(errors.country)}</div>
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                        <Input type='text' label='Postcode / ZIP' name='postcode' register={register} errors={errors} required />
                                    </div>
                                </div>

                                {isEuCountry &&
                                    <div className='row'>
                                        <div className='col-md-6'>
                                            <div className='form-group'>
                                                <label htmlFor='vatnumber'>VAT Number (optional)</label>
                                                <input type='text' className='form-control' placeholder={getValues('country') + '00000000'} {...register('vatnumber')} onChange={e => checkVatNumber(e.target.value)} />
                                                {checkingVatNumber && <p>Checking VAT Number...</p>}
                                                <small className='text-muted'>If you have a valid EU VAT number please enter it here starting with the country code</small>
                                            </div>
                                        </div>
                                    </div>
                                }

                                <div className='custom-control custom-checkbox m-4'>
                                    <div className={'form-group'}>
                                        <label className='custom-control-label'>
                                            <input type='checkbox' className={errors.acceptTermsAndConditions ? 'custom-control-input' : 'custom-control-input is-invalid'} {...register('acceptTermsAndConditions', { required: true })} /> I have read and accept the
                                        </label> <a href='#toc' onClick={() => setModalOpen(v => !v)}>Terms & Conditions</a>
                                        <div>{resolveErrorMessage(errors.acceptTermsAndConditions)}</div>
                                    </div>
                                </div>

                                <hr />
                                <h3 className='text-center m-4'>Checkout</h3>

                                {!coupon?.valid &&
                                    <div className='row'>
                                        <div className='col-md-6'>
                                            <div className='input-group mb-2 mt-2'>
                                                <input type='text' readOnly={coupon?.valid} className='form-control' {...register('couponId')} placeholder='Promo code' />
                                                <div className='input-group-append'>
                                                    {coupon?.valid
                                                        ? <button type='button' className='btn btn-signup' onClick={() => { setCoupon(null); setValue('couponId', '') }}>Remove code</button>
                                                        : <ButtonLoading loading={checkingPromoCode} className='btn btn-signup' type='button' onClick={() => validateCoupon(getValues('couponId'))}>Redeem code</ButtonLoading>}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }

                                <div className='row'>
                                    <div className='col-sm-12'>
                                        <CheckoutSummary coupon={coupon} checkoutCalculation={calculation} isEuCountry={isEuCountry} selectedProduct={selectedProduct.data} />
                                    </div>
                                </div>


                                <h3 className='text-center m-4'>Payment</h3>

                                <div className="d-grid gap-2">
                                    <Elements stripe={stripePromise} options={stripeElementOptions}>
                                        <Checkout loading={processingPayment} setProcessing={setProcessingPayment} handleSubmit={handleSubmit} />
                                    </Elements>
                                </div>

                                <h5 className='text-center text-muted mt-3'>Cancel anytime</h5>
                            </div>
                        </form>
                    </section>
                    {processingPayment && <div className='card-body m-2'><LoadingSpinner>Processing payment...</LoadingSpinner></div>}
                </div>

                {selectedProduct == null && <div className='card-body m-2'><LoadingSpinner>Loading products...</LoadingSpinner></div>}
                <TermsAndConditions onToggle={() => setModalOpen(v => !v)} isOpen={modalOpen} />
            </div>
            <FeaturesFooter />
        </>
    )
}
