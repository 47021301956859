import React, { InputHTMLAttributes } from 'react'
import { DeepRequired, FieldError, FieldErrors, FieldErrorsImpl, FieldValues, Merge, Path, UseFormRegister } from 'react-hook-form'

export type InputProps<T extends FieldValues> = {
    label: string
    name: Path<T>
    register: UseFormRegister<T>
    required?: boolean
    placeholder?: string
    errors: FieldErrors<T>
}

export function resolveErrorMessage<T>(error: FieldError | Merge<FieldError, FieldErrorsImpl<DeepRequired<T>>> | undefined) {
    if (error) {
        return error.message ? error.message.toString() : error.type === 'required' ? 'Field is required' : 'Field has errors'
    }
}

export default function Input<T extends FieldValues>({ errors, placeholder, name, label, register, required, ...rest }: InputProps<T> & InputHTMLAttributes<HTMLInputElement>) {
    return (
        <div className={required ? 'form-group required' : 'form-group'}>
            <label htmlFor={name}>{label}{!required && ' (optional)'}</label>
            <input {...rest} placeholder={placeholder ?? label} className={errors[name] ? 'form-control is-invalid' : 'form-control'} {...register(name, { required })} />
            <div className="invalid-feedback">{resolveErrorMessage(errors[name])}</div>
        </div>
    )
}
