import React, { Fragment } from 'react';
import { Card } from '../apiclients/ipassportalapiclient';
import CreditCardLogo from './creditcardlogo';

interface IProps {
    paymentMethod: Card;
}

const CardDetail: React.FC<IProps> = (props) =>
    <Fragment>
        <CreditCardLogo brand={props.paymentMethod.brand ?? ""} />
        <strong>
            {props.paymentMethod.brand} <br />
            **** **** **** {props.paymentMethod.last4}
        </strong>
        <br />
        <small>Expires: {props.paymentMethod.expMonth} / {props.paymentMethod.expYear}</small>
    </Fragment>;

export default CardDetail;
