/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CountriesResult } from '../models/CountriesResult';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class CountriesService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns CountriesResult Success
     * @throws ApiError
     */
    public countries(): CancelablePromise<CountriesResult> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/countries',
        });
    }

}
