import React from 'react';
import { Link } from 'react-router-dom';
import { Input } from 'reactstrap';
import { HostedUserModel } from '../apiclients/ipassportalapiclient';
import { useCurrentUserContext } from '../Shared/CurrentUserContext';

type UserRowProps = {
    user: HostedUserModel
    selected: boolean
    onToggle: (event: any) => void // todo fix
}

export default function UserListRow(props: UserRowProps) {
    const { currentUser } = useCurrentUserContext()

    return (
        <tr>
            <td className='wrapcolumn'><Input type='checkbox' checked={props.selected} onChange={props.onToggle} id={props.user.userId.toString()} /></td>
            <td className='pointer'>
                <div className='row'>
                    <div className='col-md-5'><Link to={'/users/edit/' + props.user.userId}>{props.user.userFirstname} {props.user.userLastname}</Link></div>
                    <div className='col-md-5'>{props.user.userEmail}</div>
                    <div className='col-md-2'>{props.user.enabled ? <span className='label label-success'>Active</span> : <span className='label label-danger'>Suspended</span>}</div>
                </div>
            </td>
            {currentUser?.roles?.includes('GlobalAdmin') && <td>{props.user.adminEmail}</td>}
            <td className='wrapcolumn'>
                <Link className='btn btn-info' to={'/users/edit/' + props.user.userId}><i className='fas fa-edit fa-fw' /></Link>{' '}
                <Link className='btn btn-danger' to={'/users/delete/' + props.user.userId}><i className='fas fa-times fa-fw' /></Link>
            </td>
        </tr>
    )
}
