import React from 'react';
import { Link } from 'react-router-dom';

const AddFirstUser = () =>
    <div className='text-center'>
        <h4>No users yet!</h4>
        <p>Click here to add first user and get started</p>
        <Link to='/users/create' className='btn btn-primary'><i className='fas fa-plus' /> Add User</Link>
    </div>;

export default AddFirstUser;
