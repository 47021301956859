/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CalculateRequest } from '../models/CalculateRequest';
import type { CalculateResponse } from '../models/CalculateResponse';
import type { GetCodeResult } from '../models/GetCodeResult';
import type { ProductsModel } from '../models/ProductsModel';
import type { StripeCardResult } from '../models/StripeCardResult';
import type { Subscription } from '../models/Subscription';
import type { ValidateCouponResult } from '../models/ValidateCouponResult';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class StripeService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns StripeCardResult Success
     * @throws ApiError
     */
    public stripeCard(): CancelablePromise<StripeCardResult> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/stripe/self',
        });
    }

    /**
     * @returns string Success
     * @throws ApiError
     */
    public updateCard(): CancelablePromise<string> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/stripe/card',
        });
    }

    /**
     * @param subscriptionId
     * @returns Subscription Success
     * @throws ApiError
     */
    public terminate(
        subscriptionId: string,
    ): CancelablePromise<Subscription> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/stripe/terminate/{subscriptionId}',
            path: {
                'subscriptionId': subscriptionId,
            },
        });
    }

    /**
     * @param subscriptionId
     * @returns Subscription Success
     * @throws ApiError
     */
    public cancelTermination(
        subscriptionId: string,
    ): CancelablePromise<Subscription> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/stripe/canceltermination/{subscriptionId}',
            path: {
                'subscriptionId': subscriptionId,
            },
        });
    }

    /**
     * @param couponId
     * @returns ValidateCouponResult Success
     * @throws ApiError
     */
    public validateCoupon(
        couponId: string,
    ): CancelablePromise<ValidateCouponResult> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/stripe/coupon/{couponId}',
            path: {
                'couponId': couponId,
            },
        });
    }

    /**
     * @returns ProductsModel Success
     * @throws ApiError
     */
    public products(): CancelablePromise<Array<ProductsModel>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/stripe/ipassproducts',
        });
    }

    /**
     * @returns GetCodeResult Success
     * @throws ApiError
     */
    public getCode(): CancelablePromise<GetCodeResult> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/stripe/generatetoken',
        });
    }

    /**
     * @param requestBody
     * @returns CalculateResponse Success
     * @throws ApiError
     */
    public calculate(
        requestBody?: CalculateRequest,
    ): CancelablePromise<CalculateResponse> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/stripe/calculate',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
