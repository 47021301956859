import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { isApiError, useApiClients } from "../../Shared/ApiClientsContext";
import { LoadingSpinner } from "../../Shared/components";
import FeaturesFooter from "../../Shared/FeaturesFooter";

export default function CompleteSignup() {
    const [loading, setLoading] = useState(false)
    const [searchParams] = useSearchParams()
    const { ipassApiClient } = useApiClients()
    const appInsights = useAppInsightsContext()
    const navigate = useNavigate()

    const payment_intent = searchParams.get('payment_intent')
    const payment_intent_client_secret = searchParams.get('payment_intent_client_secret')

    useEffect(() => {
        if (payment_intent && payment_intent_client_secret) {
            setLoading(true)
            ipassApiClient.subscription.completeSubscription({
                paymentIntent: payment_intent,
                paymentIntentClientSecret: payment_intent_client_secret
            }).then(() => {
                toast.success('Account created, good to go!')
                appInsights.trackEvent({ name: 'signup_completed', properties: { paymentIntent: payment_intent } })
                navigate('/users')
            }).catch(e => {
                if (isApiError(e)) {
                    appInsights.trackException({ properties: { ...e, paymentIntent: payment_intent } })
                }
                appInsights.trackEvent({ name: 'signup_failed', properties: { paymentIntent: payment_intent } })
                toast.error(`Signup failed: ${e}`)
                // todo.. this should probably redirect back to a saved signup form for choosing another payment method
            }).finally(() => setLoading(false))
        }
    }, [appInsights, ipassApiClient.subscription, navigate, payment_intent, payment_intent_client_secret])

    return (
        <>
            <div className='col-md-10 offset-md-1 col-lg-8 offset-lg-2'>
                <h2 className='mt-2 mb-2'>Flexinets Global Wi-Fi</h2>
                <div className='card mb-3'>
                    {loading && <div className='card-body m-2'><LoadingSpinner>Completing payment...</LoadingSpinner></div>}
                </div>
            </div>
            <FeaturesFooter />
        </>
    )
}
