/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ActivateService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @param logintoken
     * @returns any Success
     * @throws ApiError
     */
    public activate(
        logintoken: string,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'HEAD',
            url: '/ipass/{logintoken}',
            path: {
                'logintoken': logintoken,
            },
        });
    }

    /**
     * @param logintoken
     * @returns any Success
     * @throws ApiError
     */
    public activate1(
        logintoken: string,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/ipass/{logintoken}',
            path: {
                'logintoken': logintoken,
            },
        });
    }

}
