import React, { FormEvent, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import { useApiClients } from '../Shared/ApiClientsContext';
import { ButtonLoading } from '../Shared/components';

type Params = {
    userid: string
}

type Props = {
    onClosed: (userId?: number) => void
}

export default function DeleteUserModal(props: Props) {
    document.title = 'Delete user'

    const { userid } = useParams<Params>()
    const [loading, setLoading] = useState(false)
    const { ipassApiClient } = useApiClients()

    const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        if (userid) {
            const userId = parseInt(userid, 10)
            setLoading(true)

            ipassApiClient.users.delete(userId).then(() => {
                toast.info('User deleted');
                props.onClosed(userId)
            }).catch(e => {
                toast.error(`Something went wrong: ${e}`);
            })
        }
    }


    return (
        <Modal onClosed={props.onClosed} isOpen={true}>
            <form onSubmit={handleSubmit} noValidate>
                <div className='modal-content'>
                    <ModalBody>
                        <h5>Are you sure you want to delete user?</h5>
                    </ModalBody>
                    <ModalFooter>
                        <ButtonLoading className='btn btn-danger' loading={loading} type='submit'>Delete</ButtonLoading> <button type='button' className='btn btn-default' onClick={() => props.onClosed()}>Cancel</button>
                    </ModalFooter>
                </div>
            </form>
        </Modal>
    )
}
