import React from 'react';

export default function Faq() {
    document.title = 'Faq'

    return (
        <div className='container'>
            <h3>Frequently asked questions</h3>
            <a className='btn btn-info m-3' href='https://www.flexinets.eu/globalwifi_faq/' target='_blank' rel='noopener noreferrer'>Go to Frequenly Asked Questions <i className='fas fa-external-link-alt' /></a>
        </div>
    )
}
