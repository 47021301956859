import React from 'react';
import DocumentsList from './documentsList';
import NewsList from './newsList';

export default function Home() {
    document.title = 'Flexinets Global Wi-Fi'

    return (
        <div className='container'>
            <div className='row'>
                <div className='col-md-6'>
                    <div className='card newsbox'>
                        <div className='card-body'>
                            <NewsList />
                        </div>
                    </div>
                </div>
                <div className='col-md-6'>
                    <div className='card newsbox'>
                        <div className='card-body'>
                            <DocumentsList />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

