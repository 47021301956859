import React, { InputHTMLAttributes, useCallback, useEffect } from 'react';
import { FieldValues } from 'react-hook-form';
import { CountryModel } from '../apiclients/ipassportalapiclient';
import { useApiClients } from '../Shared/ApiClientsContext';
import { Countries } from './countries';
import { InputProps } from './TextInput';


export default function CountrySelect<T extends FieldValues>({ errors, name, register, required, onCountryChanged, ...rest }: InputProps<T> & InputHTMLAttributes<HTMLSelectElement> & { onCountryChanged: (country: CountryModel) => void }) {
    const { anonymousIpassApiClient } = useApiClients()

    const changeCountry = useCallback((countryCode: string) => {
        const country = Countries.filter((o) => o.code === countryCode)[0]
        if (onCountryChanged) {
            onCountryChanged(country);
        }
    }, [onCountryChanged])

    useEffect(() => {
        anonymousIpassApiClient.location.location().then(location => {
            if (location.Success && location.CountryCode) {
                changeCountry(location.CountryCode)
            }
        })
    }, [anonymousIpassApiClient.location, changeCountry])

    return (
        <select {...rest} className={errors[name] ? 'form-control is-invalid' : 'form-control'} {...register(name, { required })} onChange={e => changeCountry(e.target.value)}>
            <option value='' disabled>Select country...</option>
            {Countries.map(country => <option key={country.code} value={country.code}>{country.name}</option>)}
        </select>
    )
}
