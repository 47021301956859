import React, { createContext, useContext, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom";
import { authenticationapiclientclient, TokenResponse } from "../apiclients/authenticationapiclient"
import { AppConfig } from "../appConfig";
import { clearTokenFromLocalStorage, currentUserFromAccessToken, getTokenFromLocalStorage, isJwtTokenExpired, setTokenToLocalStorage } from "./CurrentUserUtils";

export type Roles = 'GlobalAdmin' | 'Admin'
export type CurrentUser = {
    emailAddress: string
    firstName: string
    lastName: string
    roles: readonly Roles[] | undefined
}

type AsyncState = 'init' | 'pending' | 'completed'

type CurrentUserContextModel = {
    authenticationApiClient: Readonly<authenticationapiclientclient>
    currentUser: CurrentUser | undefined
    jwtToken: TokenResponse | undefined
    isLoggedIn: boolean | undefined
    login: (username: string, password: string) => Promise<boolean>
    logout: () => Promise<void>
    beginReset: (emailAddress: string, returnUrl: string) => Promise<void>
    validateResetToken: (resetId: string) => Promise<boolean>
    completeReset: (password: string, passwordConfirm: string, resetId: string) => Promise<boolean>;
    checkEmailAvailability: (emailAddress: string) => Promise<boolean>
    getRefreshedAccessToken: () => Promise<string>
}

const CurrentUserContext = createContext<CurrentUserContextModel | undefined>(undefined)
let refreshPromise: Promise<TokenResponse> | null = null
const authenticationApiClient = new authenticationapiclientclient({ BASE: AppConfig.REACT_APP_AUTHENTICATION_BASE_URL, WITH_CREDENTIALS: true })

export function CurrentUserContextProvider({ children }: { children: React.ReactNode }) {
    const [token, setToken] = useState<TokenResponse | undefined>()
    const [currentUser, setCurrentUser] = useState<CurrentUser | undefined>()
    const [tokenState, setTokenState] = useState<AsyncState>('init')
    const navigate = useNavigate()


    useEffect(() => {
        if (tokenState === 'init') {
            const token = getTokenFromLocalStorage()
            if (token) {
                setToken(token)
                setCurrentUser(currentUserFromAccessToken(token.access_token))
            }

            setTokenState('completed')
        }
    }, [tokenState])


    const setTokenContext = (jwt: TokenResponse) => {
        setTokenToLocalStorage(jwt)
        setToken(jwt)
        setTokenState('completed')
    }


    const clearTokenContext = () => {
        clearTokenFromLocalStorage()
        setToken(undefined)
        setCurrentUser(undefined)
        setTokenState('completed')
    }


    const login = async (username: string, password: string) => {
        clearTokenContext();

        try {
            const result = await authenticationApiClient.token.token({
                grant_type: 'password',
                Username: username,
                Password: password,
            })

            setTokenContext(result)
            setCurrentUser(currentUserFromAccessToken(result.access_token))

            return true
        } catch (e) {
            console.error('hu?')
            console.error(e)
        }

        return false
    }

    const logout = async () => {
        clearTokenContext();
        authenticationApiClient.token.logout()
    }

    const getRefreshedAccessToken = async () => {
        let jwtToken = token
        try {
            if (jwtToken) {
                return isJwtTokenExpired(jwtToken.access_token)
                    ? (await refreshAccessToken()).access_token
                    : jwtToken.access_token
            }
        } catch (error) {
            console.debug(error)
            clearTokenContext()
        }

        navigate('/login')
        return ''
    }

    const refreshAccessToken = () => {
        if (refreshPromise === null) {
            refreshPromise = authenticationApiClient.token.token({
                grant_type: 'refresh_token'
            }).then(response => {
                setTokenContext(response)
                return response
            }).catch(e => {
                clearTokenContext()
                return Promise.reject("unable to refresh access token")
            }).finally(() => {
                refreshPromise = null
            })
        }

        return refreshPromise;
    }

    const beginReset = async (email: string, returnUrl: string) => authenticationApiClient.account.beginReset({
        EmailAddress: email,
        ReturnUrl: returnUrl,
    })

    const completeReset = async (password: string, confirmPassword: string, resetId: string) => authenticationApiClient.account.completeReset({
        password: password,
        passwordConfirm: confirmPassword,
        resetId: resetId,
    })

    const determineIfLoggedIn = () => (tokenState === 'completed' && token === undefined)
        ? false
        : token !== undefined ? token.refresh_token_expires > new Date().getTime() / 1000 : undefined


    return (
        <CurrentUserContext.Provider value={{
            authenticationApiClient: new authenticationapiclientclient({ BASE: AppConfig.REACT_APP_AUTHENTICATION_BASE_URL, WITH_CREDENTIALS: true, TOKEN: getRefreshedAccessToken }),
            currentUser: currentUser,
            jwtToken: token,
            isLoggedIn: determineIfLoggedIn(),
            login: login,
            logout: logout,
            beginReset: beginReset,
            validateResetToken: async (resetId) => await authenticationApiClient.account.validateResetToken(resetId),
            completeReset: completeReset,
            checkEmailAvailability: async email => email === '' ? true : (await authenticationApiClient.account.checkEmailAvailability(email)).available,
            getRefreshedAccessToken: getRefreshedAccessToken
        }}>
            {children}
        </CurrentUserContext.Provider>
    )
}

export const useCurrentUserContext = () => {
    const context = useContext(CurrentUserContext)
    if (context) {
        return context
    }

    throw Error('Context undefined? Forgot a provider somewhere?')
}