import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import Admins from './Admins/Admins';
import Faq from './Faq/Faq';
import Home from './Home/home';
import CompleteReset from './Login/CompleteReset';
import Login from './Login/Login';
import Menu from './menu';
import ServiceAlerts from './ServiceAlerts/ServiceAlerts';
import { ApiClientsContextProvider } from './Shared/ApiClientsContext';
import { CurrentUserContextProvider } from './Shared/CurrentUserContext';
import Footer from './Shared/Footer';
import PrivateRoute from './Shared/PrivateRoute';
import ShopTokens from './ShopTokens/ShopTokens';
import CompleteSignup from './Signup/Complete/CompleteSignup';
import Signup from './Signup/Signup';
import Subscription from './Subscription/Subscription';
import Users from './Users/Users';

export default function App() {
    return (
        <BrowserRouter>
            <CurrentUserContextProvider>
                <ApiClientsContextProvider>
                    <div className='modal-blur'>
                        <Menu />
                        <div className='site-content'>
                            <Routes>
                                <Route index path='/' element={<PrivateRoute><Home /></PrivateRoute>} />
                                <Route path='/users/*' element={<PrivateRoute><Users /></PrivateRoute>} />
                                <Route path='/subscription/*' element={<PrivateRoute><Subscription /></PrivateRoute>} />
                                <Route path='/servicealerts/*' element={<PrivateRoute><ServiceAlerts /></PrivateRoute>} />
                                <Route path='/faq/*' element={<PrivateRoute><Faq /></PrivateRoute>} />
                                <Route path='/shoptokens/*' element={<PrivateRoute><ShopTokens /></PrivateRoute>} />
                                <Route path='/admins/*' element={<PrivateRoute><Admins /></PrivateRoute>} />
                                <Route path='/login/*' element={<Login />} />
                                <Route path='/reset/:resetid' element={<CompleteReset />} />
                                <Route path='/signup/*' element={<Signup />} />
                                <Route path='/signup/complete/*' element={<PrivateRoute><CompleteSignup /></PrivateRoute>} />
                            </Routes>
                        </div>
                        <Footer />
                    </div>
                    <ToastContainer />
                </ApiClientsContextProvider>
            </CurrentUserContextProvider>
        </BrowserRouter>
    )
}
