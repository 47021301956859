import { CalculateResponse, CouponModel, ProductsModel } from '../apiclients/ipassportalapiclient';

type Props = {
    checkoutCalculation: CalculateResponse | undefined
    coupon: CouponModel | null,
    selectedProduct: ProductsModel | null,
    isEuCountry: boolean,
}

export default function CheckoutSummary(props: Props) {
    return (
        <table className='table table-striped users-table'>
            <thead>
                <tr>
                    <td>Product</td>
                    <td className='text-right'>Quantity</td>
                    <td className='text-right'>Price</td>
                </tr>
            </thead>
            {props.selectedProduct
                ? <tbody>
                    <tr>
                        {props.selectedProduct === null
                            ? <td colSpan={3}><span className='skeleton-box'></span></td>
                            : <><td>{props.selectedProduct.title}</td>
                                <td className='text-right'>{props.checkoutCalculation?.quantity}</td>
                                <td className='fln-price'>{((props.checkoutCalculation?.price ?? 0) / 100).toFixed(2)} {props.selectedProduct.currency}</td>
                            </>
                        }
                    </tr>
                    {props.coupon?.valid && props.checkoutCalculation?.discountAmount
                        ? <tr>
                            <td colSpan={2}>{props.coupon.name}, [Code {props.coupon.id}]</td>
                            <td className='fln-price'>{(-props.checkoutCalculation?.discountAmount / 100).toFixed(2)} {props.selectedProduct?.currency}</td>
                        </tr>
                        : null
                    }
                    {props.isEuCountry &&
                        <tr>
                            <td colSpan={2}>VAT {props.checkoutCalculation?.vatPercent ?? ''}% <small className='text-muted'>(For EU VAT exemption, fill in a VAT number)</small></td>
                            <td className='fln-price'>{((props.checkoutCalculation?.taxAmountExclusive ?? 0) / 100).toFixed(2)} {props.selectedProduct?.currency}</td>
                        </tr>
                    }
                    <tr>
                        <td colSpan={2}><strong>Total</strong></td>
                        <td className='fln-price'><strong>{(((props.checkoutCalculation?.amountTotal ?? 0) / 100)).toFixed(2)} {props.selectedProduct?.currency}</strong></td>
                    </tr>
                </tbody>
                : <tbody>
                    <tr><td colSpan={3}><span className='skeleton-box'></span></td></tr>
                    <tr><td colSpan={3}><span className='skeleton-box'></span></td></tr>
                </tbody>
            }
        </table>
    )
}
