import React from 'react';
import { PaymentMethod } from '../apiclients/ipassportalapiclient';
import { ButtonLoading } from '../Shared/components';
import { useCurrentUserContext } from '../Shared/CurrentUserContext';
import CardDetail from './CardDetail';
import { Route, Routes, useNavigate } from 'react-router-dom';
import UpdatePaymentMethodModal from './UpdatePaymentMethodModal';

type Props = {
    onCardChanged: () => void | Promise<void>;
    cards: readonly PaymentMethod[];
}

export default function Cards(props: Props) {
    const navigate = useNavigate()
    const { currentUser } = useCurrentUserContext()

    return (
        <div className='card mb-2'>
            <div className='card-body'>
                <h4 className='card-title'>Payment method</h4>
                {props.cards.length > 0 &&
                    <>
                        <h5 className='card-subtitle mb-2 text-muted'>Your current card</h5>
                        <div className='row'>
                            <div className='col-sm-8'>
                                <CardDetail paymentMethod={props.cards[0].card!} />
                            </div>
                        </div>
                    </>
                }
                {props.cards.length === 0 &&
                    <h5 className='card-subtitle mb-2 text-muted'>Invoice to {currentUser?.emailAddress}</h5>
                }
            </div>
            <div className='card-footer'><ButtonLoading loading={false} type='button' onClick={() => { navigate('updatepaymentmethod') }} className='btn btn-info'><i className='fas fa-edit' /> Update card</ButtonLoading></div>

            <Routes>
                <Route path='updatepaymentmethod' element={<UpdatePaymentMethodModal onClosed={() => null} />} />
            </Routes>
        </div>
    )
}

