/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BaseHttpRequest } from './core/BaseHttpRequest';
import type { OpenAPIConfig } from './core/OpenAPI';
import { FetchHttpRequest } from './core/FetchHttpRequest';

import { ActivateService } from './services/ActivateService';
import { AdminsService } from './services/AdminsService';
import { CountriesService } from './services/CountriesService';
import { DocumentsService } from './services/DocumentsService';
import { HealthService } from './services/HealthService';
import { LocationService } from './services/LocationService';
import { NewsService } from './services/NewsService';
import { StripeService } from './services/StripeService';
import { SubscriptionService } from './services/SubscriptionService';
import { UsersService } from './services/UsersService';

type HttpRequestConstructor = new (config: OpenAPIConfig) => BaseHttpRequest;

export class ipassapiclient {

    public readonly activate: ActivateService;
    public readonly admins: AdminsService;
    public readonly countries: CountriesService;
    public readonly documents: DocumentsService;
    public readonly health: HealthService;
    public readonly location: LocationService;
    public readonly news: NewsService;
    public readonly stripe: StripeService;
    public readonly subscription: SubscriptionService;
    public readonly users: UsersService;

    public readonly request: BaseHttpRequest;

    constructor(config?: Partial<OpenAPIConfig>, HttpRequest: HttpRequestConstructor = FetchHttpRequest) {
        this.request = new HttpRequest({
            BASE: config?.BASE ?? '',
            VERSION: config?.VERSION ?? '1',
            WITH_CREDENTIALS: config?.WITH_CREDENTIALS ?? false,
            CREDENTIALS: config?.CREDENTIALS ?? 'include',
            TOKEN: config?.TOKEN,
            USERNAME: config?.USERNAME,
            PASSWORD: config?.PASSWORD,
            HEADERS: config?.HEADERS,
            ENCODE_PATH: config?.ENCODE_PATH,
        });

        this.activate = new ActivateService(this.request);
        this.admins = new AdminsService(this.request);
        this.countries = new CountriesService(this.request);
        this.documents = new DocumentsService(this.request);
        this.health = new HealthService(this.request);
        this.location = new LocationService(this.request);
        this.news = new NewsService(this.request);
        this.stripe = new StripeService(this.request);
        this.subscription = new SubscriptionService(this.request);
        this.users = new UsersService(this.request);
    }
}

