import { CouponModel, ProductsModel, SignupType } from "../apiclients/ipassportalapiclient";

export default function SubscriptionTypeSummary({ coupon, selectedProduct }: { coupon: CouponModel | null, selectedProduct: ProductsModel | null, signupType: SignupType }) {
    return (
        <div className='product'>
            <h2 className='mt-2 mb-2'>Flexinets iPass</h2>
            <hr />

            {coupon === null &&
                <h5 className='text-muted'>
                    {selectedProduct !== null
                        ? <>{selectedProduct.price / 100} {selectedProduct.currency.toUpperCase()} / Month</>
                        : <span className="skeleton-box" style={{ width: 300 }}></span>
                    }
                </h5>}
            {coupon?.valid && coupon.amountOff && selectedProduct &&
                <>
                    <h4>{((selectedProduct.price - coupon.amountOff) / 100).toFixed(2)} {selectedProduct.currency.toUpperCase()}<small><span className='text-muted small'> first month</span></small></h4>
                    <small><p className='text-muted'>then {selectedProduct.price / 100} {selectedProduct.currency.toUpperCase()} / month</p></small>
                </>
            }
            <small className='text-muted'>Eligible for tablet, phone or laptop</small>
            <hr />
            <h5>Cancel anytime</h5>
        </div>
    )
}
