import React, { useEffect, useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { toast } from 'react-toastify';
import { ButtonLoading } from '../Shared/components';
import { useCurrentUserContext } from '../Shared/CurrentUserContext';
import { isApiError } from '../Shared/ApiClientsContext';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { useForm } from 'react-hook-form';
import { AdminModelIpassPortal } from '../apiclients/authenticationapiclient';
import Input from '../Signup/TextInput';


export default function Account(props: { onClosed: () => void }) {
    const [loading, setLoading] = useState(false)
    const { authenticationApiClient } = useCurrentUserContext()
    const appInsights = useAppInsightsContext()

    const { register, handleSubmit, formState: { errors }, setValue } = useForm<AdminModelIpassPortal>({ mode: 'all', defaultValues: {} })

    useEffect(() => {
        authenticationApiClient.account.getAccount().then((account => {
            setValue('EmailAddress', account.EmailAddress)
            setValue('Phonenumber', account.Phonenumber)
            setValue('Fullname', account.Fullname)
        }))
    }, [authenticationApiClient.account, setValue])

    const handleFormSubmit = handleSubmit(async (formData, errors) => {
        if (!errors) {
            setLoading(true)

            try {
                await authenticationApiClient.account.updateAccount(formData)

                toast.success('Your account was saved')
                props.onClosed()
            } catch (e) {
                if (isApiError(e)) {
                    appInsights.trackException({ properties: { ...e } })
                }
                toast.error(`Something went wrong: ${e}`);
            }

            setLoading(false)
        }
    })

    return (
        <Modal onClosed={props.onClosed} isOpen={true} toggle={props.onClosed}>
            <form onSubmit={e => { e.preventDefault(); handleFormSubmit() }}>
                <div className="modal-content">
                    <ModalHeader>My account</ModalHeader>
                    <ModalBody>
                        <Input type='email' label='Email' name='EmailAddress' placeholder='email@example.com' register={register} errors={errors} required />
                        <Input type='tel' label='Phone number' name='Phonenumber' placeholder='Phone number with country code' register={register} errors={errors} />
                        <Input type='text' label='Name' name='Fullname' placeholder='Firstname Lastname' register={register} errors={errors} required />
                    </ModalBody>
                    <ModalFooter>
                        <ButtonLoading className="btn btn-primary" loading={loading} disabled={loading} type="submit" onSubmit={e => { e.preventDefault(); handleFormSubmit() }}>Save changes</ButtonLoading> <button type="button" className="btn btn-default" onClick={props.onClosed}>Cancel</button>
                    </ModalFooter>
                </div>
            </form>
        </Modal >
    )
}
