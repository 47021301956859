import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { AppInsightsContext, ReactPlugin } from '@microsoft/applicationinsights-react-js';
import React from 'react';
import 'react-toastify/dist/ReactToastify.css';
import App from './App';
import { AppConfig } from './appConfig';
import './styles/site.scss';
import { createRoot } from 'react-dom/client';

var reactPlugin = new ReactPlugin()

if (AppConfig.REACT_APP_APPINSIGHTS_INSTRUMENTATION_KEY) {
    var appInsights = new ApplicationInsights({
        config: {
            instrumentationKey: AppConfig.REACT_APP_APPINSIGHTS_INSTRUMENTATION_KEY,
            enableAutoRouteTracking: true,
            extensions: [reactPlugin],
        }
    })

    const userId = document.cookie.replace(/(?:(?:^|.*;\s*)appinsights_userid\s*=\s*([^;]*).*$)|^.*$/, "$1")
    appInsights.loadAppInsights()
    if (userId.length > 0) {
        appInsights.setAuthenticatedUserContext(decodeURI(userId), undefined, true)
    }

    appInsights.trackPageView({ name: document.title, refUri: document.referrer, uri: document.URL })
}

const container = document.getElementById('root')
const root = createRoot(container!)
root.render(
    <React.StrictMode>
        <AppInsightsContext.Provider value={reactPlugin}>
            <App />
        </AppInsightsContext.Provider>
    </React.StrictMode>
)
