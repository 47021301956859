import React, { Component } from 'react';

class CreditCardLogo extends Component<{ brand: string }, {}> {

    getCreditCardLogo = () => {
        let src = '';
        if (this.props.brand.toLowerCase() === 'visa') {
            src = '/Content/img/visa.svg';
        } else if (this.props.brand.toLowerCase() === 'mastercard') {
            src = '/Content/img/mastercard.svg';
        } else if (this.props.brand.toLowerCase() === 'american express') {
            src = '/Content/img/amex.svg';
        }

        return src;
    }

    render() {
        return <img alt='cclogo' className='cclogo mr-2' src={this.getCreditCardLogo()} />;
    }
}

export default CreditCardLogo;
