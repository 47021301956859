import { Link } from 'react-router-dom';
import { AdminModel } from '../apiclients/ipassportalapiclient';

export default function AdminListRow(props: { admin: AdminModel }) {
    return (
        <tr>
            <td className='pointer'>
                <div className='row'>
                    <div className='col-md-5'><Link to={'/admins/edit/' + props.admin.adminId}>{props.admin.email}</Link></div>
                    <div className='col-md-5'>{props.admin.firstname} {props.admin.lastname}</div>
                </div>
            </td>
            <td className='wrapcolumn'>
                <Link className='btn btn-info' to={'/admins/edit/' + props.admin.adminId}><i className='fas fa-edit fa-fw' /></Link>{' '}
                <Link className='btn btn-danger' to={'/admins/delete/' + props.admin.adminId}><i className='fas fa-times fa-fw' /></Link>
            </td>
        </tr>
    )
}
