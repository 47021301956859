/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AdminModel } from '../models/AdminModel';
import type { AdminModelIpassPortal } from '../models/AdminModelIpassPortal';
import type { ChangePasswordModel } from '../models/ChangePasswordModel';
import type { CheckEmailAvailabilityResult } from '../models/CheckEmailAvailabilityResult';
import type { ResetModel } from '../models/ResetModel';
import type { ResetPasswordModel } from '../models/ResetPasswordModel';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class AccountService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns AdminModel Success
     * @throws ApiError
     */
    public getAccount(): CancelablePromise<AdminModel> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/account',
        });
    }

    /**
     * @param requestBody
     * @returns number Success
     * @throws ApiError
     */
    public updateAccount(
        requestBody?: AdminModelIpassPortal,
    ): CancelablePromise<number> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/account',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody
     * @returns number Success
     * @throws ApiError
     */
    public changePassword(
        requestBody?: ChangePasswordModel,
    ): CancelablePromise<number> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/account/changepassword',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody
     * @returns any Success
     * @throws ApiError
     */
    public beginReset(
        requestBody?: ResetModel,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/account/resetpassword/beginreset',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id
     * @returns boolean Success
     * @throws ApiError
     */
    public validateResetToken(
        id: string,
    ): CancelablePromise<boolean> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/account/resetpassword/validateresettoken/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param requestBody
     * @returns boolean Success
     * @throws ApiError
     */
    public completeReset(
        requestBody?: ResetPasswordModel,
    ): CancelablePromise<boolean> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/account/resetpassword/completereset',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param email
     * @returns CheckEmailAvailabilityResult Success
     * @throws ApiError
     */
    public checkEmailAvailability(
        email?: string,
    ): CancelablePromise<CheckEmailAvailabilityResult> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/checkemailavailability',
            query: {
                'email': email,
            },
        });
    }

}
