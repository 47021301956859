import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

export default function TermsAndConditions(props: { isOpen: boolean, onToggle: () => void }) {
    return (
        <Modal isOpen={props.isOpen} size="lg" toggle={props.onToggle}>
            <ModalHeader>Terms and Conditions</ModalHeader>
            <ModalBody>
                <h1>End User Agreement</h1>
                <ol>
                    <li>
                        <h2>The Service</h2>
                        <p>
                            <strong>“IPass Global Wi-Fi”</strong> is a service that provides You (thereinafter “You”) with access to seamless Internet connectivity supplied by Flexible Networks Nordic AB (the “Service”) through the global Wi-Fi network owned by iPass Inc. (“iPass”). This End User Agreement (“Agreement”) is an agreement between You and Flexible Networks Nordic AB (acting under trade name “Flexinets”) which governs the use of the Service and its related software. Please read carefully the terms and conditions provided for in this Agreement. When subscribing to the Service by making use of an order form (“Order Form”) available on www.flexinets.eu (“Website”), You agree that You are over the age of 18 (eighteen) and You further agree to and fully accept the terms of this Agreement and agree to be bound by them for its whole duration.
                        </p>
                    </li>
                    <li>
                        <h2>Registration</h2>
                        <p>
                            In order to be able to use the Service You must first register by filling in an Order Form available on www.flexinets.eu
                        </p>
                        <p>
                            When registering you must provide complete and accurate details including your first name, last name, valid e-mail address, phone number, address of residence, credit card details that shall and need to be updated by You if necessary. After completing the subscription process and on the  condition  that Flexinets receives the first payment for the Service, You will be provided a personalized activation link which will enable You to download the required software and activate the  Service  on  a  particular device (up to 3 personal devices). You agree not to share the activation link with any other person. Additional users created by the End User on the customer portal will be charged according to the pricelist at the end of each term.<br />
                            In order to access the Service you will need a compatible laptop, tablet, smartphone and or other device with embedded Wi-F technology.
                        </p>
                        <p>
                            Flexinets reserves the right and You agree to allow Flexinets to automatically download updates to Your device as needed. From time to time, and in Flexinets sole discretion, Flexinets may need to temporarily suspend the Service in order to perform various updates. Flexinets intends to notify you in advance of such scheduled updates.
                        </p>
                    </li>
                    <li>
                        <h2>Right to Use the Service</h2>
                        <p>
                            Subject to the terms of this Agreement and during the term of this Agreement, Flexinets hereby grants you a non-exclusive and non- transferable right to install and use the Service on Your devices for Your own personal use. Flexinets may modify the Service at any time, for any reason, and without providing You with notice of such modification. Modifications will be intended to add functionality, solve bugs or otherwise improve and update the Service.
                        </p>
                    </li>
                    <li>
                        <h2>Billing and Pricing</h2>
                        <p>
                            When subscribing to the Service You will be obliged to provide the accurate details of Your credit card to which Flexinets will charge the applicable monthly recurring subscription fee (“Subscription Fee”). Thereafter, Flexinets will be automatically collecting the at that moment applicable Subscription at the end of each Subscription Term unless terminated by You or Flexinets in accordance with Section 5.
                        </p>
                        <p>
                            Should Flexinets not be able to collect the Subscription Fee by the end of the then current Subscription Term, Flexinets shall have the right to suspend the provision of the Service to You up until Flexinets manages to collect the unpaid Subscription Fee(s) and charge reasonable late fees and/or collection costs. Such costs as well as any unpaid Subscription Fee(s) will continue to accrue to Your account until You cancel or Flexinets cancels Your account.
                        </p>
                        <p>
                            You are responsible for providing complete and accurate billing and contact information to Flexinets and updating Your account settings if there are any changes to such information. It is Your responsibility to ensure that You have sufficient means on Your credit card and that it will be valid during the term of this Agreement.
                        </p>
                        <p>
                            You will be charged in accordance with the current pricing displayed on the Website and such pricing being subject to changes at Flexinets’ sole discretion provided that it has notified You via Your email one (1) calendar month in advance.
                        </p>
                    </li>
                    <li>
                        <h2>Term and Termination</h2>
                        <p>
                            This Agreement and Your use of  the Service shall commence once Flexinets has validated your information and deducted Your first Subscription Fee  (“Effective Date”). The Agreement shall continue to be in force for at least a period of 30 days following the Effective Date (“Initial Term”) and on the day of expiry of Initial Term shall continue on a monthly basis (any month of subscription to be referred to as “Subscription Term”) until terminated in accordance with this Agreement. You may cancel this Agreement at any time by cancelling Your subscription on the customer portal https://wifi.flexinets.se/login in which case termination will be effective after expiry of Initial Term. To illustrate, if Your current Subscription Term ends on 15th July and You decide to Terminate the Agreement on 10th July, the Agreement will be terminated on the 15th of July.
                        </p>
                        <p>
                            With no obligation to reimburse You, Flexinets is entitled to immediately suspend or terminate this contract entered into between Flexinets and You for the Service, Your password, Your account, and/or Your use of any Service in the event that: (a) Flexinets believes that You have breached any term of this Agreement or iPass’ Acceptable Use Policy set out in Section 7 of this Agreement; or (b) if Flexinets decides to stop providing the Service by giving a thirty days prior notice; (c) if Flexinets is not able to provide Services anymore due to termination of the contractual relationship with its supplier, iPass.
                        </p>
                    </li>
                    <li>
                        <h2>Acceptable Use Policy</h2>
                        <p>
                            You may only use the Service in ways that comply with the law in the jurisdictions in which you use the Service. Among other things, You shall not use the Service to take any actions or make any statements that, by way of example only:
                        </p>

                        <ol>
                            <li>infringe on any third party’s copyright, patent, trademark, trade secret or other proprietary rights or rights of publicity or privacy;</li>
                            <li>violate any applicable law, statute, ordinance or regulation (including without limitation those regarding export control);</li>
                            <li>are defamatory, trade libelous or unlawfully threatening;</li>
                            <li>are pornographic or obscene;</li>
                            <li>violate any laws regarding unfair competition, antidiscrimination or false advertising;</li>
                            <li>result in the distribution of viruses, Trojan horses, worms, time bombs, cancelbots, chain letters or other similar harmful or deleterious programming routines;</li>
                            <li>result in the unauthorized entry to any other machine accessible via the network;</li>
                            <li>permit other individuals to use the Service;</li>
                            <li>modify, translate, reverse engineer, decompile, disassemble the Service, except as permitted by applicable law;</li>
                            <li>otherwise use the Service in a manner not permitted by this Agreement.</li>
                        </ol>
                    </li>
                    <li>
                        <h2>Privacy Policy</h2>
                        <p>
                            You agree that Flexinets and the suppliers it utilizes to provide the Service to You (under which “iPass”) shall have the right to access Your account and to use, modify, reproduce, distribute, display and disclose Your Personal Data solely to the extent necessary to provide the Service, including, without limitation, in response to Your support requests. Such Personal Data shall include the following information: Your name, address, phone number, email address, billing information; in connection with an account sign-in facility, Your log-in and password details; details of any purchase transactions made by you through the Website.
                        </p>
                        <p>
                            Flexinets will process Your Personal Data in accordance with the applicable EU data protection laws and declares to comply with all the obligations arising from such laws.
                        </p>
                        <p>
                            Any third party providers of services (“Third Party Providers”) Flexinets and / or iPass utilizes will only be given access to Your Personal Data as is reasonably necessary to provide the Service and will be subject to confidentiality obligations. Flexinets and / or iPass may also access or disclose information about You and
                        </p>
                        <p>
                            Your Personal Data in order to: (a) comply with the law or respond to lawful requests or legal process; (b) protect Flexinets’s, iPass’, their customers’ or partners’ rights or property, including enforcement of this Agreement or other policies associated with the Service; and (c) act on a good faith belief that such disclosure is necessary to protect personal safety or avoid violation of applicable law or regulation.
                        </p>
                        <p>
                            Flexinets and iPass - as Flexinets’s supplier - collect certain information about You including but not limited to the location of the Your device when using the Service, and information regarding the devices, computers and Your use of the Service. Flexinets and iPass use, disclose, collect and protect this information as described in iPass’ Privacy Policy, the then- current version of which is available at www.ipass.com/privacy and is incorporated by reference herein.
                        </p>
                        <p>
                            You agree to be bound by the usage policies of iPass including its Privacy Policy. You acknowledge and agree that Flexinets and/or iPass may disclose Your usage data to other parties as detailed in iPass Privacy Policy. You accept that certain uses of Your personal information are necessary to enable provision of the Service to you and accordingly that if You withdraw Your consent for Flexinets and/or iPass to use Your data as is detailed in iPass Privacy Policy the supply of the Service to You may have to be terminated. You acknowledge and agree that You must exercise Your own due diligence before relying on any information available on the Internet, and must determine that You have all necessary rights to copy, publish or otherwise distribute any such information available on the Internet under copyright and other applicable laws.
                        </p>
                    </li>
                    <li>
                        <h2>Technical Support</h2>
                        <p>
                            In case You have any question or experience issue(s) with regard to the use of the Service, You may address such question or issue to Flexinets via an online form available on the
                        </p>
                        <p>
                            Website under section “Contact”. Such support is provided to You with regard to the Service either directly by Flexinets or through its partner iPass during CET office hours (9:00h- 17:00h). Flexinets will use reasonable efforts to troubleshoot and resolve issues reported by You but Flexinets does not make any representations or guarantees that Flexinets will be able to resolve any such issues. Except as provided in this Section 9, Flexinets has no obligation to provide additional customer support, technical support, or to provide solutions to any issues that may arise in your particular use of the Service.
                        </p>
                    </li>
                    <li>
                        <h2>Disclaimer of Warranty</h2>
                        <p>
                            By its very nature, the Internet contains offensive and/or harmful material, in some cases under descriptions that have been mislabeled or are otherwise deceptive. Neither Flexinets, iPass nor their suppliers shall be responsible for any damages suffered by You and/or any person as a result of obtaining Internet access. Access to the Internet is provided solely on an “AS IS” basis. Neither Flexinets, iPass or their suppliers warrant, nor do they assume responsibility for, any consequences suffered by any person as a  result of Internet access including, without limitation, those suffered as a result of accessing Internet information and content, such as, by way of example only, the possibility of contracting computer viruses, accessing information with offensive, inaccurate or inappropriate content. Flexinets, iPass or its suppliers exercise no control whatsoever on the content of the information passing through their systems.
                        </p>
                        <p>
                            Notwithstanding anything else in this Agreement, to the maximum extent permitted by applicable law, the Service are provided on an "as is" and "as available" basis, and Flexinets, iPass and their suppliers, disclaim and make no representations or warranties and there are no conditions of any kind, either express, implied or statutory, including but not limited to any warranties	of	non-interference, merchantability, fitness for a particular purpose or use, or arising from course of dealing or usage of trade. You assume all responsibility and risk for use of the Service. Any statements made in any written or electronic documents, or by any Flexinets or iPass employees, representatives, or agents are provided for informational purposes only and not as representations or warranties of any kind. Flexinets, iPass and their suppliers do not warrant that the Service will be uninterrupted, error free, or free from service degradation, or that any information, software, or other material accessible on the Service are free from viruses, worms, Trojan horses, or other code that manifests contaminating, interfering, or destructive properties. Flexinets, iPass and their suppliers cannot and do not guarantee the security or integrity of data transmission or storage, or that viruses, worms, Trojan horses, or other code that manifests contaminating or destructive properties will be detected or remediated by the Service. The Service  are only available within the coverage area of the iPass network, which is subject to change. You acknowledge that the Service may be temporarily unavailable for maintenance, equipment modifications, or upgrades, and for other reasons within and or beyond the direct control of Flexinets and iPass.
                        </p>
                    </li>
                    <li>
                        <h2>Limitation of Liability</h2>
                        <p>
                            To the maximum extent permitted by applicable law, in no event shall Flexinets, iPass or their suppliers be liable for any special, incidental or consequential damages, or for interrupted communications, lost data or lost profits, arising out of or in connection with the Service.
                        </p>
                    </li>
                    <li>
                        <h2>Indemnity</h2>
                        <p>
                            You agree to indemnify, defend and hold harmless Flexinets and its officers, directors, employees, agents, successors and assigns from and against any loss, liability or claim, including claim for reasonable attorney's fees, brought by any third party and arising from: (a)
                        </p>
                        <p>
                            Your infringement of any third party's copyright, patent, trademark, trade secret or other proprietary rights or rights of publicity or privacy through use of the Service; (b) Your violation of any applicable law, statute, ordinance or regulation when using the Service;
                            (c) violation of iPass’ acceptable use policy provided for in Section 7 of this Agreement; and/or (d) material breach of this Agreement.
                        </p>
                    </li>
                    <li>
                        <h2>Third party beneficiaries</h2>
                        <p>
                            iPass and its suppliers shall be deemed to be third-party beneficiaries of this Agreement, with the right to enforce the terms of this Agreement. You agree that this Agreement is expressly for the benefit of Flexinets, iPass and their suppliers and may be enforced by them.
                        </p>
                    </li>
                    <li>
                        <h2>Proprietary Rights</h2>
                        <p>
                            You acknowledge that the Service and its related software contain the valuable secrets of Flexinets, iPass and their suppliers and You agree not to cause or permit the reverse engineering, translation, disassembly, or decompilation of, or otherwise attempt to derive the source code of, the Service and its software, whether in whole or in part. You will not use, reproduce, modify, prepare derivative works of, distribute, sublicense, loan, sell, or otherwise transfer the Service and its software in any manner or for any purpose except as expressly permitted herein.
                        </p>
                        <p>
                            Flexinets, iPass and their suppliers will retain all title, copyright and other proprietary and intellectual property rights in and to the Service and its software, and any other technology, services, or materials that Flexinets may provide to You hereunder or in conjunction with the Service. All rights in and to the foregoing not expressly granted to You in this Agreement are reserved by Flexinets, iPass and their suppliers. In particular, but without limiting the generality of the foregoing, no right to or license in the source code for the Service and its software is granted hereunder. You will not obfuscate, alter, or remove any copyright, trademark, or other proprietary notice or legend on or in the Service and its software. Further, You agree that You will not: (1) resell any part of the Service to third parties; (2) share Your Service connection with anyone.
                        </p>
                    </li>
                    <li>
                        <h2>Amendments</h2>
                        <p>
                            Flexinets may, in its sole discretion, make amendments to the terms and conditions of this Agreement. If Flexinets makes any such amendments, Flexinets will strive to notify You of such changes by sending a notification to the email address on file in your Account. Notwithstanding, any such amendment shall be effective upon publication on the Website and You are responsible for regularly checking for any such updates.
                        </p>
                    </li>
                    <li>
                        <h2>Severability</h2>
                        <p>
                            If any provision of this Agreement is held to be unenforceable by a court of competent jurisdiction, that provision will either be deemed modified to the minimum extent necessary to render it enforceable, or else disregarded. In either case, the remaining provisions shall continue in full force and effect.
                        </p>
                    </li>
                    <li>
                        <h2>Assignment</h2>
                        <p>
                            You may not assign the Agreement, the use of the Service or your rights and obligations under this Agreement without the prior written consent of Flexinets. Any such assignment shall be void.
                        </p>
                    </li>
                    <li>
                        <h2>Entire Agreement</h2>
                        <p>
                            This Agreement together with the Order Form You have submitted via Website constitute the entire agreement between the Parties with regard to the Service provided hereunder. All prior agreements, proposals, representations and other understandings whether oral or written, between you and Flexinets are superseded in their entirety by this Agreement.
                        </p>
                    </li>
                    <li>
                        <h2>Survival</h2>
                        <p>
                            This Section as well as Sections 8 (Privacy Policy),	11	(Limitation	of	Liability),	12 (Indemnity), 13  (Third Party  Beneficiaries), 14 (Proprietary   Rights),   15   (Amendments),  17 (Assignment), 18 (Entire Agreement), 20 (Governing Law) and any other Sections that reasonably should survive termination of the Agreement) shall survive termination of the Agreement with respect to use of the Service by You. Termination of the Agreement shall not limit Your liability for obligations accrued as of or prior to such termination or for any breach of this Agreement.
                        </p>
                    </li>
                    <li>
                        <h2>Governing Law</h2>
                        <p>
                            This Agreement and the use of Service shall be governed by the laws of Sweden and all disputes arising between you and Flexinets are to be brought before the competent court in the Sweden unless mandatory law determines that the dispute must be brought before another court.
                        </p>
                    </li>
                </ol>
            </ModalBody>
            <ModalFooter>
                <button type="button" className="btn btn-info" onClick={props.onToggle}>Close</button>
            </ModalFooter>
        </Modal>
    )
}