import React, { useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { toast } from 'react-toastify';
import { ButtonLoading } from '../Shared/components';
import { useCurrentUserContext } from '../Shared/CurrentUserContext';
import { isApiError } from '../Shared/ApiClientsContext';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { useForm } from 'react-hook-form';
import Input from '../Signup/TextInput';


export default function ChangePassword(props: { onClosed: () => void }) {
    const [loading, setLoading] = useState(false)
    const { authenticationApiClient } = useCurrentUserContext()
    const appInsights = useAppInsightsContext()

    const { register, handleSubmit, formState: { errors } } = useForm<{
        oldPassword: string,
        newPassword: string
    }>({ mode: 'all', defaultValues: {} })


    const handleFormSubmit = handleSubmit(async (formData, errors) => {
        if (!errors) {
            setLoading(true)

            try {
                await authenticationApiClient.account.changePassword({
                    OldPassword: formData.oldPassword,
                    Password: formData.newPassword,
                    PasswordConfirm: formData.newPassword,
                })

                toast.success('Your password has been changed');
                props.onClosed()
            } catch (e) {
                if (isApiError(e)) {
                    appInsights.trackException({ properties: { ...e } })
                }
                toast.error(`Something went wrong: ${e}`);
            }
        }
    })


    return (
        <Modal onClosed={props.onClosed} isOpen={true} toggle={props.onClosed}>
            <form onSubmit={e => { e.preventDefault(); handleFormSubmit() }}>
                <div className="modal-content">
                    <ModalHeader>Change password</ModalHeader>
                    <ModalBody>
                        <Input type='password' label='Old password' name='oldPassword' placeholder='Old password' register={register} errors={errors} required />
                        <Input type='password' label='New password' name='newPassword' placeholder='New password' register={register} errors={errors} required />
                    </ModalBody>
                    <ModalFooter>
                        <ButtonLoading className="btn btn-primary" onSubmit={e => { e.preventDefault(); handleFormSubmit() }} disabled={loading} loading={loading} type="submit">Change password</ButtonLoading> <button type="button" className="btn btn-default" onClick={props.onClosed}>Cancel</button>
                    </ModalFooter>
                </div>
            </form>
        </Modal >
    )
}
