import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { NewsItemModel } from '../apiclients/ipassportalapiclient';
import { useApiClients } from '../Shared/ApiClientsContext';

export default function NewsList() {
    const { ipassApiClient } = useApiClients()
    const [loading, setLoading] = useState(false)
    const [newsItems, setNewsItems] = useState<NewsItemModel[]>([])

    useEffect(() => {
        setLoading(true)
        ipassApiClient.news.news().then(result => {
            setNewsItems(result)
            setLoading(false)
        })
    }, [ipassApiClient.news])

    return (
        <>
            <h4>News</h4>
            <div className='news'>
                {loading && <div className='chartloading' />}
                {!loading && newsItems.map((item, index) =>
                    <article key={index}>
                        <span className='newsdate'>{moment(item.createdOn).format('MMMM Do')}</span>
                        <header>{item.title}</header>
                        <p dangerouslySetInnerHTML={{ __html: item.text }} />
                        <a href={item.url}>Read more</a>
                    </article>
                )}
            </div>
        </>
    )
}
