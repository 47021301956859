import React, { useState } from 'react';
import { PaymentElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { StripePaymentElementOptions } from '@stripe/stripe-js'
import { ButtonLoading } from '../Shared/components';
import { useApiClients } from '../Shared/ApiClientsContext';
import { UseFormHandleSubmit } from 'react-hook-form'
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { useCurrentUserContext } from '../Shared/CurrentUserContext';
import { SignupModel, StripeError } from '../apiclients/ipassportalapiclient';
import { toast } from 'react-toastify';

// these are collected from the signup form so dont collect them again during checkout
const paymentElementOptions: StripePaymentElementOptions = {
    fields: {
        billingDetails: {
            address: {
                country: 'never',
                postalCode: 'never',
            }
        }
    }
}

export default function Checkout(props: { loading: boolean, setProcessing: (loading: boolean) => void, handleSubmit: UseFormHandleSubmit<SignupModel> }) {
    const stripe = useStripe()
    const elements = useElements()
    const { anonymousIpassApiClient, ipassApiClient } = useApiClients()
    const appInsights = useAppInsightsContext()
    const [showApplePay, setShowApplePay] = useState(false)
    const { login, isLoggedIn } = useCurrentUserContext()
    const [clientSecret, setClientSecret] = useState<string | undefined>()
    const [error, setError] = useState<StripeError | undefined>()
    const [submitDisabled, setSubmitDisabled] = useState(false)

    const handleCheckoutSubmit = props.handleSubmit(async (formData, formErrors) => {
        const { password, ...payload } = formData

        appInsights.trackEvent({ name: 'signup_submit_attempt', properties: payload })

        if (formErrors) {
            setSubmitDisabled(false)
            return
        }

        setError(undefined)


        appInsights.trackEvent({ name: 'signup_submit', properties: payload })

        if (!stripe || !elements) {
            setSubmitDisabled(false)
            return
        }

        props.setProcessing(true)
        setSubmitDisabled(true)
        const { error: submitError } = await elements.submit()
        if (submitError) {
            appInsights.trackException({ properties: { ...submitError } })
            props.setProcessing(false)
            setSubmitDisabled(false)
            return
        }
        window.scrollTo(0, 0)

        const secret = clientSecret ?? (isLoggedIn ? await ipassApiClient.subscription.createSubscription(formData) : await anonymousIpassApiClient.subscription.createSubscription(formData))
        setClientSecret(secret)

        if (!isLoggedIn) {
            await login(formData.email, formData.password)
        }

        if (secret !== undefined) {
            const { error } = await stripe.confirmPayment({
                elements,
                clientSecret: secret,
                confirmParams: {
                    payment_method_data: {
                        billing_details: {
                            email: formData.email,
                            name: formData.name,
                            address: {
                                country: formData.country,
                                postal_code: formData.postcode,
                            }
                        }
                    },
                    return_url: `${window.location.protocol}//${window.location.host}/signup/complete`,
                },
            })

            if (error) {
                setError(error)
                toast.error(`Payment was declined: ${error.message}`)
                appInsights.trackException({ properties: { ...error } })
            }
        }

        props.setProcessing(false)
        setSubmitDisabled(false)
    })

    return (
        <>
            <PaymentError error={error} />
            <PaymentElement options={paymentElementOptions} onChange={e => setShowApplePay(e.value.type === 'apple_pay')} />
            {showApplePay
                ? <button className='apple-pay-button apple-pay-button-subscribe apple-pay-button-black' disabled={props.loading} onClick={e => { e.preventDefault(); handleCheckoutSubmit() }} ></button>
                : <ButtonLoading type='submit' onClick={e => { e.preventDefault(); handleCheckoutSubmit() }} disabled={submitDisabled} loading={props.loading} className='btn btn-block btn-lg btn-pay'>Pay and Create Account</ButtonLoading>
            }
        </>
    )
}

function PaymentError({ error }: { error: StripeError | undefined }) {
    return error
        ? <div>Payment failed: {error.message}</div>
        : <></>
}