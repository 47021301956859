import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { isApiError } from '../Shared/ApiClientsContext';
import { ButtonLoading, LoadingSpinner } from '../Shared/components';
import { useCurrentUserContext } from '../Shared/CurrentUserContext';
import Input from '../Signup/TextInput';


export default function CompleteReset() {
    document.title = 'Complete Reset'

    const [loading, setLoading] = useState(false)
    const [isValid, setIsValid] = useState<boolean>(false)
    const { isLoggedIn, validateResetToken, completeReset } = useCurrentUserContext()
    const navigate = useNavigate()
    const params = useParams<{ resetid: string }>()
    const [resetId, setResetId] = useState('')
    const appInsights = useAppInsightsContext()
    const { register, handleSubmit, formState: { errors } } = useForm<{
        password: string,
        resetId: string,
    }>({ mode: 'all', defaultValues: {} })


    useEffect(() => {
        if (isLoggedIn) {
            navigate('/');
        }

        if (params.resetid) {
            validateResetToken(params.resetid).then((response) => {
                if (response) {
                    setIsValid(true)
                    setResetId(params.resetid ?? '')
                }
            })
        }
    }, [validateResetToken, isLoggedIn, params.resetid, navigate])

    const handleFormSubmit = handleSubmit(async (formData, errors) => {
        if (!errors) {
            setLoading(true)

            try {
                await completeReset(formData.password, formData.password, resetId);
                toast.success('Your password has been set, you can now log in');
                navigate('/login');
            } catch (e) {
                if (isApiError(e)) {
                    appInsights.trackException({ properties: { ...e } })
                }
                toast.error('uh oh, something went wrong');
            }

            setLoading(false)
        }
    })

    return (
        <div className='col-md-6 offset-md-3'>
            <div className='card mt-3'>
                <div className='card-body'>
                    <form className='form-signin' onSubmit={e => { e.preventDefault(); handleFormSubmit() }}>
                        <h5 className='text-center mb-4'>Flexinets Global Wi-Fi - Password reset</h5>
                        {isValid === undefined && <LoadingSpinner>Getting reset info...</LoadingSpinner>}
                        {isValid !== undefined &&
                            <>
                                {!isValid
                                    ? <>
                                        <p className='text-center'>This reset link is either invalid, expired or has already been used.</p>
                                        <div className='text-center'><h5><Link to='/login/reset'>Begin reset?</Link></h5></div>
                                    </>
                                    : <>
                                        <Input type='password' label='New password' name='password' placeholder='New password' register={register} errors={errors} required />
                                        <ButtonLoading type='submit' onSubmit={e => { e.preventDefault(); handleFormSubmit() }} loading={loading} disabled={loading} className='btn btn-block btn-primary mt-4 mb-4'>Set new password</ButtonLoading>
                                    </>
                                }
                            </>
                        }
                    </form>
                </div>
            </div>
        </div >
    )
}
