declare global {
    // eslint-disable-next-line no-unused-vars
    interface Window { _env_: Record<AppConfigKeys, string> }
}

export type AppConfigKeys =
    'REACT_APP_BACKEND_BASE_URL'
    | 'REACT_APP_AUTHENTICATION_BASE_URL'
    | 'REACT_APP_API_BASE_URL'
    | 'REACT_APP_APPINSIGHTS_INSTRUMENTATION_KEY'
    | 'REACT_APP_STRIPE_PK'

const getValue = (key: AppConfigKeys) => process.env.NODE_ENV === 'production' ? window._env_[key] : (process.env[key] ?? '')

export const AppConfig: Record<AppConfigKeys, string> = {
    REACT_APP_BACKEND_BASE_URL: getValue('REACT_APP_BACKEND_BASE_URL'),
    REACT_APP_AUTHENTICATION_BASE_URL: getValue('REACT_APP_AUTHENTICATION_BASE_URL'),
    REACT_APP_API_BASE_URL: getValue('REACT_APP_API_BASE_URL'),
    REACT_APP_APPINSIGHTS_INSTRUMENTATION_KEY: getValue('REACT_APP_APPINSIGHTS_INSTRUMENTATION_KEY'),
    REACT_APP_STRIPE_PK: getValue('REACT_APP_STRIPE_PK')
}
