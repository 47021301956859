import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { ButtonLoading } from '../Shared/components';
import SubscriptionDetail from './SubscriptionDetail';
import { Invoice, Subscription } from '../apiclients/ipassportalapiclient';
import { isApiError, useApiClients } from '../Shared/ApiClientsContext';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';

export default function Subscriptions(props: { subscriptions: readonly Subscription[], nextInvoice: Invoice | null | undefined, onSubscriptionChanged: () => void }) {
    const [loading, setLoading] = useState(false)
    const [confirm, setConfirm] = useState(false)
    const { ipassApiClient } = useApiClients()
    const appInsights = useAppInsightsContext()

    const terminate = async (subscriptionId: string) => {
        setLoading(true)

        try {
            await ipassApiClient.stripe.terminate(subscriptionId)
            toast.success('Your subscription has been terminated');
            props.onSubscriptionChanged();
        }
        catch (e) {
            if (isApiError(e)) {
                appInsights.trackException({ properties: { ...e } })
                toast.error(`Something went wrong: ${e.message}`)
            }
        }

        setLoading(false)
        setConfirm(false)
    }

    const cancelTermination = async (subscriptionId: string) => {
        setLoading(true)

        try {
            await ipassApiClient.stripe.cancelTermination(subscriptionId)
            toast.success('Your subscription has been resumed');
            props.onSubscriptionChanged();
        }
        catch (e) {
            if (isApiError(e)) {
                appInsights.trackException({ properties: { ...e } })
                toast.error(`Something went wrong: ${e.message}`)
            }
        }

        setLoading(false)
        setConfirm(false)
    }

    return (
        <div className="card mb-2">
            <div className="card-body">
                <h4 className="card-title">Subscription</h4>
                <h5 className="card-subtitle mb-2 text-muted">Your current subscriptions</h5>
                {props.subscriptions.map((subscription) => <SubscriptionDetail subscription={subscription} nextInvoice={props.nextInvoice} key={subscription.id!} />)}
                {props.subscriptions.length === 0 &&
                    <div>
                        <p>You do not have any active subscriptions</p>
                        {/* Link to signup or something... */}
                    </div>}
            </div>
            {props.subscriptions.length > 0 && props.subscriptions[0].canceledAt === null &&
                <div className="card-footer">
                    {!confirm && <button onClick={() => setConfirm(true)} type="button" className="btn btn-info"><i className="fas fa-ban" /> End Subscription</button>}
                    {confirm &&
                        <>
                            <button type="button" className="btn btn-default" onClick={() => setConfirm(false)}>Cancel</button>
                            <ButtonLoading loading={loading} className="btn btn-danger float-end" onClick={() => terminate(props.subscriptions[0].id!)}>Yes, end subscription</ButtonLoading>
                        </>
                    }
                </div>
            }
            {props.subscriptions.length > 0 && props.subscriptions[0].canceledAt !== null &&
                <div className="card-footer">
                    {!confirm && <button onClick={() => setConfirm(true)} type="button" className="btn btn-info">Re-activate Subscription</button>}
                    {confirm &&
                        <>
                            <button type="button" className="btn btn-default" onClick={() => setConfirm(false)}>Cancel</button>
                            <ButtonLoading loading={loading} className="btn btn-info float-end" onClick={() => cancelTermination(props.subscriptions[0].id!)}>Yes, re-activate</ButtonLoading>
                        </>
                    }
                </div>
            }
        </div>
    )
}
