import React from 'react';

const Footer = () =>
    <footer>
        <address>
            <strong>Contact us</strong><br />
            <a href='https://www.flexinets.eu/contact-en/'>flexinets.eu/contact/</a><br />
            <a href='mailto:support@flexinets.eu'>support@flexinets.eu</a>
        </address>
    </footer>;

export default Footer;
