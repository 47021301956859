import React from 'react';
import moment from 'moment';
import { Invoice, Subscription } from '../apiclients/ipassportalapiclient';

export default function SubscriptionDetail(props: { subscription: Subscription, nextInvoice: Invoice | null | undefined }) {
    return (
        <div className="row">
            <div className="col">
                {props.subscription.status === 'trialing' && <div>Trial ends:  {moment(props.subscription.trialEnd ?? '').format('YYYY MMMM DD')}<br /></div>}
                {props.subscription.canceledAt === null ? 'Next payment' : 'Subscription ends'} : <strong>{moment(props.subscription.currentPeriodEnd ?? '').format('YYYY MMMM DD')}</strong><br />
                {props.nextInvoice && <> Amount: {props.nextInvoice.total / 100} {props.nextInvoice.currency?.toUpperCase()} <br /></>}
            </div>
        </div>
    )
}