/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AdminModel } from '../models/AdminModel';
import type { CreateAdminModel } from '../models/CreateAdminModel';
import type { EditAdminModel } from '../models/EditAdminModel';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class AdminsService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @param id
     * @returns AdminModel Success
     * @throws ApiError
     */
    public getAdmin(
        id: number,
    ): CancelablePromise<AdminModel> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/admins/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns AdminModel Success
     * @throws ApiError
     */
    public listAdmins(): CancelablePromise<Array<AdminModel>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/admins',
        });
    }

    /**
     * @param requestBody
     * @returns AdminModel Success
     * @throws ApiError
     */
    public createAdmin(
        requestBody?: CreateAdminModel,
    ): CancelablePromise<AdminModel> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/admins',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param adminId
     * @param requestBody
     * @returns AdminModel Success
     * @throws ApiError
     */
    public editAdmin(
        adminId: number,
        requestBody?: EditAdminModel,
    ): CancelablePromise<AdminModel> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/admins/{adminId}',
            path: {
                'adminId': adminId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param adminId
     * @returns number Success
     * @throws ApiError
     */
    public deleteAdmin(
        adminId: number,
    ): CancelablePromise<number> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/admins/{adminId}',
            path: {
                'adminId': adminId,
            },
        });
    }

}
