import { CurrentUser } from "./CurrentUserContext"
import decode from 'jwt-decode';
import { TokenResponse } from "../apiclients/authenticationapiclient";

export function currentUserFromAccessToken(accessToken: string): CurrentUser {
    // todo this is perhaps a bit optimistic...
    const claims: any = decode(accessToken)
    const user: CurrentUser = {
        emailAddress: claims['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress'],
        firstName: claims['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/givenname']!,
        lastName: claims['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/surname']!,
        roles: claims['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'],
    }

    return user
}

export function isJwtTokenExpired(accessToken: string) {
    const now = new Date();
    const jwtToken = decode(accessToken) as any;
    if (!jwtToken.exp) {
        return true;
    }

    const expirationDate = new Date(0);
    expirationDate.setUTCSeconds(jwtToken.exp);
    return expirationDate < now;
}

const STORAGE_KEY = 'react_token'

export function getTokenFromLocalStorage(): TokenResponse | undefined {
    const tokenString = localStorage.getItem(STORAGE_KEY)
    if (tokenString) {
        return JSON.parse(tokenString) as TokenResponse // type guard?
    }
}

export function setTokenToLocalStorage(token: TokenResponse) {
    localStorage.setItem(STORAGE_KEY, JSON.stringify(token))
}

export function clearTokenFromLocalStorage() {
    localStorage.removeItem(STORAGE_KEY)
}