import moment from 'moment';
import React from 'react';
import { ChargeModel } from '../apiclients/ipassportalapiclient';
import CardDetail from './CardDetail';

interface IProps {
    charges: readonly ChargeModel[];
}

const Charges: React.FC<IProps> = props =>
    <div className='card mb-2'>
        <div className='card-body'>
            <h4 className='card-title'>Past payments</h4>
            {props.charges.length === 0 && <p className='help-block'>No charges on your card yet</p>}

            {props.charges.length > 0 &&
                <ul className='list-group'>
                    {props.charges.map((charge, index) =>
                        <li key={index} className='list-group-item'>
                            <div className='row'>
                                <div className='col-5'>
                                    Amount:  {charge.amount / 100} {charge.currency.toUpperCase()} <br />
                                    <small>Date: {moment.unix(charge.created).format('YYYY MMMM DD')}</small><br />
                                    <small>Status: {charge.status}</small>
                                </div>
                                <div className='col-7'>
                                    {charge.source && <CardDetail paymentMethod={charge.source} />}
                                </div>
                            </div>
                        </li>,
                    )}
                </ul>
            }
        </div>
    </div>;

export default Charges;
