import React from 'react';
import './FeaturesFooter.scss'

const FeaturesFooter = () =>
    <div className='features-footer'>
        <div className='container'>
            <div className='row'>
                <div className='col-6 col-md-2'>
                    <div className='featurebox-item'>
                        <span className='featurebox-icon'><i className='fas fa-plane' /></span>
                        <h1>Inflight Wi-Fi</h1>
                        on 5.190 airplanes worldwide
                    </div>
                </div>
                <div className='col-6 col-md-2'>
                    <div className='featurebox-item'>
                        <span className='featurebox-icon'><i className='fas fa-wifi' /></span>
                        <h1>68 Million hotspots</h1>
                        in 180 countries
                    </div>
                </div>
                <div className='col-6 col-md-2'>
                    <div className='featurebox-item'>
                        <span className='featurebox-icon'><i className='fas fa-sign-in-alt' /></span>
                        <h1>Automatic login</h1>
                        to best available network (optional)
                    </div>
                </div>
                <div className='col-6 col-md-2'>
                    <div className='featurebox-item'>
                        <span className='featurebox-icon'><i className='fas fa-lock' /></span>
                        <h1>Advanced security with VPN</h1>
                        keeping your private data secure
                    </div>
                </div>
                <div className='col-6 col-md-2'>
                    <div className='featurebox-item'>
                        <span className='featurebox-icon'><i className='fas fa-sync' /></span>
                        <h1>Be well connected</h1>
                        Unlimited Premium wifi<br />
                        1GB Inflight data / month
                    </div>
                </div>
                <div className='col-6 col-md-2'>
                    <div className='featurebox-item'>
                        <span className='featurebox-icon'><i className='fas fa-mobile' /></span>
                        <h1>Multi device support</h1>
                        Eligible for tablet, phone or laptop
                    </div>
                </div>
            </div>
        </div>
    </div>;

export default FeaturesFooter;
