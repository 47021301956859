import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import debounce from 'lodash.debounce';
import React, { FormEvent, Fragment, useEffect, useRef, useState } from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';
import { Option } from 'react-bootstrap-typeahead/types/types';
import { Link, Route, Routes, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, DropdownItem, DropdownMenu, DropdownToggle, Input, UncontrolledDropdown } from 'reactstrap';
import { AdminModel, HostedUserModel, SubscriptionStatus } from '../apiclients/ipassportalapiclient';
import { isApiError, useApiClients } from '../Shared/ApiClientsContext';
import { AsyncActionState } from '../Shared/AsyncActionState';
import { LoadingSpinner } from '../Shared/components';
import { useCurrentUserContext } from '../Shared/CurrentUserContext';
import AddFirstUser from './AddFirstUser';
import DeleteUserModal from './DeleteUser';
import UserDetail from './UserDetail';
import UserListRow from './UserListRow';

export default function Users() {
    document.title = 'Users'

    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const { ipassApiClient } = useApiClients()
    const { currentUser, jwtToken } = useCurrentUserContext()
    const appInsights = useAppInsightsContext()

    const [selectedUsers, setSelectedUsers] = useState<Set<number>>(new Set())
    const [searchString, setSearchString] = useState('')
    const [customerFilter, setCustomerFilter] = useState<number | undefined>()
    const [users, setUsers] = useState<HostedUserModel[]>([])
    const [subscriptionStatus, setSubscriptionStatus] = useState<SubscriptionStatus>()
    const [admins, setAdmins] = useState<AsyncActionState<readonly AdminModel[]>>({ data: [], state: 'init' })


    useEffect(() => {
        if (currentUser?.roles?.includes('GlobalAdmin')) {
            setAdmins(o => ({ ...o, state: 'pending' }))
            ipassApiClient.admins.listAdmins().then(list => {
                setAdmins({ data: list, state: 'success' })
            })
        }
    }, [ipassApiClient.admins, currentUser?.roles])


    useEffect(() => {
        setLoading(true)
        ipassApiClient.users.users().then(result => {
            setUsers(result.users)
            setSubscriptionStatus(result.subscriptionStatus)
            setLoading(false)
        }).catch(e => {
            if (isApiError(e)) {
                appInsights.trackException({ properties: { ...e } })
                toast.error(`Something went wrong retrieving users: ${e.message}`)
            }
        })
    }, [ipassApiClient.users, appInsights])

    const debouncedSearch = useRef(
        debounce(async (search) => {
            await fetchUsers(search, customerFilter)
        }, 300)
    ).current;

    const fetchUsers = async (searchString: string | undefined, customerFilter: number | undefined) => {
        try {
            const result = await ipassApiClient.users.users(searchString, customerFilter?.toString())
            setUsers(result.users)
            setSubscriptionStatus(result.subscriptionStatus)
        }
        catch (e) {
            if (isApiError(e)) {
                appInsights.trackException({ properties: { ...e } })
                toast.error(`Something went wrong retrieving users: ${e.message}`)
            }
        }
    }

    const onClosed = async (result: any) => {
        if (result) {
            await fetchUsers(searchString, customerFilter)
        }

        navigate('/users')
        document.title = 'Users'
    }


    const onDeleteUserClosed = (userId?: number | undefined) => {
        if (userId) {
            setUsers(users.filter(o => o.userId !== userId))
        }

        navigate('/users')
        document.title = 'Users'
    }


    const toggleUser = (event: any) => {
        const userId = parseInt(event.target.id, 10);
        const updated = new Set(Array.from(selectedUsers))  // this is insane... fix
        event.target.checked ? updated.add(userId) : updated.delete(userId)
        setSelectedUsers(updated)
    }

    // todo fix any
    const toggleAllUsers = (event: any) => setSelectedUsers(event.target.checked ? new Set(users.map(o => o.userId)) : new Set());


    const sendAppInvite = async (users: Set<number>) => {
        try {
            await ipassApiClient.users.invite(Array.from(users))
            toast.success(`App invite sent to ${users.size} users`)
        }
        catch (e) {
            if (isApiError(e)) {
                appInsights.trackException({ properties: { ...e } })
                toast.error(`Something went wrong: ${e.message}`)
            }
        }
    }

    const filterCustomers = (selected: Option[]) => {
        if (selected.length === 1) {
            const customerFilter = (selected[0] as { id: number }).id
            setCustomerFilter(customerFilter)
            fetchUsers(searchString, customerFilter)
        }
        else {
            setCustomerFilter(undefined)
            fetchUsers(searchString, undefined)
        }
    }


    const handleSearch = (event: FormEvent<HTMLInputElement>) => {
        setSearchString(event.currentTarget.value)
        debouncedSearch(event.currentTarget.value)
    }

    return (
        <div className='container'>
            <h3>Users</h3>
            {loading && <LoadingSpinner>Getting users...</LoadingSpinner>}

            {!loading && subscriptionStatus !== 'Active' &&
                <div className='card mb-3'>
                    <div className='card-body'>
                        <i className='fas fa-exclamation-triangle' /> You dont have any active subscriptions. To re-activate go to <Link to={'/signup'}><Button className='btn btn-info btn-sm'>Signup</Button></Link>
                    </div>
                </div>
            }

            {!loading && users.length === 0 && searchString === '' && customerFilter === undefined && <AddFirstUser />}

            {!loading && (users.length > 0 || searchString !== '' || customerFilter !== undefined) &&
                <div className='card mb-3'>
                    <div className='card-body'>
                        <div className='float-start'>
                            <Link to='/users/create' className='btn btn-primary'><i className='fas fa-plus' /> Add User</Link>{' '}
                            {currentUser?.roles?.includes('GlobalAdmin') &&
                                <Fragment><a href={'/api/export/users/?access_token=' + jwtToken!.access_token} className='btn btn-info' target='_self'><i className='fas fa-file-export' /> Export</a>{' '}</Fragment>
                            }
                            <div className='btn-group'>
                                <UncontrolledDropdown>
                                    <DropdownToggle color='info' caret>Selected Users</DropdownToggle>
                                    <DropdownMenu>
                                        <DropdownItem tag='a' className='pointer' disabled={selectedUsers.size === 0} onClick={() => sendAppInvite(selectedUsers)}><i className='fas fa-mobile-alt fa-fw' /> Send app invite</DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                            </div>{' '}
                            [<strong>{selectedUsers.size}</strong> selected]
                        </div>
                        <div className='float-end'>
                            <span className='input-group'>
                                {currentUser?.roles?.includes('GlobalAdmin') && <Typeahead id='admin' style={{ width: 300 }} onChange={filterCustomers} placeholder='Filter customers...' options={admins.data.map(admin => ({ id: admin.adminId, label: `${admin.email} (${admin.stripeCustomerId})` }))}></Typeahead>}
                                <input name='Search' type='search' value={searchString} onChange={handleSearch} className='form-control' placeholder='Search users...' />
                                <span className='input-group-btn'>
                                    <button type='submit' ng-click='search(model.searchterm)' className='btn btn-default'><i className='fas fa-search' /></button>
                                </span>
                            </span>
                        </div>

                        <table className='table table-hover users-table mt-3'>
                            <thead>
                                <tr className='d-none d-md-table-row'>
                                    <td className='wrapcolumn'><Input type='checkbox' id='toggleAll' onChange={toggleAllUsers} /></td>
                                    <td>
                                        <div className='row'>
                                            <strong className='col-md-5'>Name</strong>
                                            <strong className='col-md-5'>Email</strong>
                                            <strong className='col-md-2'>Status</strong>

                                        </div>
                                    </td>
                                    {currentUser?.roles?.includes('GlobalAdmin') && <td><strong>Owner</strong></td>}
                                    <td className='wrapcolumn' />
                                </tr>
                            </thead>
                            <tbody>
                                {users.map((user) => <UserListRow key={user.userId} user={user} selected={selectedUsers.has(user.userId) || false} onToggle={toggleUser} />)}
                            </tbody>
                        </table>
                    </div>
                </div>
            }

            <Routes>
                <Route path='create' element={<UserDetail onClosed={onClosed} />} />
                <Route path='edit/:userid' element={<UserDetail onClosed={onClosed} />} />
                <Route path='delete/:userid' element={<DeleteUserModal onClosed={onDeleteUserClosed} />} />
            </Routes>
        </div>
    )
}
