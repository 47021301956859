import React from 'react';
import { Navigate } from 'react-router-dom';
import { LoadingSpinner } from './components';
import { useCurrentUserContext } from './CurrentUserContext';

export default function PrivateRoute({ children }: { children: JSX.Element }) {
    const { isLoggedIn } = useCurrentUserContext()

    return isLoggedIn === undefined
        ? <div className='card-body m-2'><LoadingSpinner>Loading...</LoadingSpinner></div>
        : isLoggedIn
            ? children
            : <Navigate to={{ pathname: '/login' }} />
}
