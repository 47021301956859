import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { DocumentModel } from '../apiclients/ipassportalapiclient';
import { useApiClients } from '../Shared/ApiClientsContext';

export default function DocumentsList() {
    const { ipassApiClient } = useApiClients()
    const [loading, setLoading] = useState(false)
    const [documents, setDocuments] = useState<DocumentModel[]>([])

    useEffect(() => {
        setLoading(true)
        ipassApiClient.documents.get(10).then(result => {
            setDocuments(result)
            setLoading(false)
        })
    }, [ipassApiClient.documents])

    return (
        <>
            <h4>Latest documents</h4>
            <div className='news'>
                {loading && <div className='chartloading' />}
                <table className='documentstable'>
                    <tbody>
                        {!loading && documents.map((item, index) =>
                            <tr key={index}>
                                <td>{moment(item.Created).format('MMMM Do')}</td>
                                <td><a href={'https://documents.flexinets.se/documents/download/' + item.Filename}>{item.Filename}</a></td>
                            </tr>,
                        )}
                    </tbody>
                </table>
            </div>
        </>
    )
}
