import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { AdminModel, CreateAdminModel } from "../apiclients/ipassportalapiclient";
import { useApiClients } from "../Shared/ApiClientsContext";
import { ButtonLoading } from "../Shared/components";
import { resolveErrorMessage } from "../Signup/TextInput";


type Props = {
    onClosed: (admin?: AdminModel) => void
}

export default function AdminDetailModal(props: Props) {
    const { register, setValue, handleSubmit, formState: { errors }, watch } = useForm<CreateAdminModel>({
        mode: 'all', defaultValues: {}
    })

    const { email } = watch()

    const { adminId } = useParams<{ adminId?: string }>()
    const [loading, setLoading] = useState(false)
    const { ipassApiClient } = useApiClients()

    useEffect(() => {
        if (adminId) {
            ipassApiClient.admins.getAdmin(Number.parseInt(adminId, 10)).then(admin => {
                setValue('email', admin.email)
                setValue('firstname', admin.firstname)
                setValue('lastname', admin.lastname)
                setValue('phonenumber', admin.phonenumber)
            })
        }
    }, [adminId, ipassApiClient.admins, setValue])

    const handleSave = handleSubmit((formData, errors) => {
        if (!errors) {
            setLoading(true)

            const promise = adminId
                ? ipassApiClient.admins.editAdmin(Number.parseInt(adminId, 10), formData)
                : ipassApiClient.admins.createAdmin(formData)

            promise.then(result => {
                props.onClosed(result)
                setLoading(false)
            })
        }
    })

    return (
        <Modal onClosed={props.onClosed} isOpen={true}>
            <div className='modal-content'>
                <ModalHeader>{adminId ? `Edit admin ${email}` : 'Create admin'}</ModalHeader>
                <ModalBody>
                    <div className='row'>
                        <div className='col-md-6'>
                            <div className='form-group required'>
                                <label htmlFor='name'>Email</label>
                                <input type='email' autoComplete='username' placeholder='email@example.com' className={errors.email ? 'form-control is-invalid' : 'form-control'}  {...register('email', { required: true })} />
                                <div className="invalid-feedback">{resolveErrorMessage(errors.email)}</div>
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className='form-group required'>
                                <label htmlFor='name'>Password</label>
                                <input type='password' className={errors.password ? 'form-control is-invalid' : 'form-control'}  {...register('password', { required: !!!adminId })} />
                                <div className="invalid-feedback">{resolveErrorMessage(errors.password)}</div>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-md-6'>
                            <div className='form-group required'>
                                <div className="form-check">
                                    <label className="form-check-label" htmlFor="mustchangepassword">
                                        Must change password
                                    </label>
                                    <input className="form-check-input" type="checkbox" value="" id="mustchangepassword" {...register('mustChangePassword')}>

                                    </input>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-md-6'>
                            <div className='form-group required'>
                                <label htmlFor='name'>Firstname</label>
                                <input type='text' placeholder='Firstname' className={errors.firstname ? 'form-control is-invalid' : 'form-control'} {...register('firstname', { required: true })} />
                                <div className="invalid-feedback">{resolveErrorMessage(errors.firstname)}</div>
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className='form-group required'>
                                <label htmlFor='name'>Lastname</label>
                                <input type='text' placeholder='Lastname' className={errors.lastname ? 'form-control is-invalid' : 'form-control'}  {...register('lastname', { required: true })} />
                                <div className="invalid-feedback">{resolveErrorMessage(errors.lastname)}</div>
                            </div>
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <ButtonLoading className='btn btn-primary' loading={loading} onClick={e => { e.preventDefault(); handleSave() }} type='submit'>{adminId ? 'Save admin' : 'Create admin'}</ButtonLoading> <button type='button' className='btn btn-default' onClick={() => props.onClosed()}>Cancel</button>
                </ModalFooter>
            </div>
        </Modal >
    )
}