import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { ButtonLoading } from '../Shared/components';
import { PaymentElement, useElements, useStripe, Elements } from "@stripe/react-stripe-js";
import { useApiClients } from '../Shared/ApiClientsContext';
import { loadStripe, StripeElementsOptions } from '@stripe/stripe-js';
import { AppConfig } from '../appConfig';
import { toast } from 'react-toastify';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';

const stripePromise = loadStripe(AppConfig.REACT_APP_STRIPE_PK)
const setupOptions: StripeElementsOptions = {
    mode: 'setup',
    currency: 'eur',
    paymentMethodTypes: ['card']
}


export default function UpdatePaymentMethodModal(props: { onClosed: () => void }) {
    document.title = 'Update payment method'

    const navigate = useNavigate()
    const onClosed = () => navigate('/subscription')

    return (
        <Elements stripe={stripePromise} options={setupOptions}>
            <UpdatePaymentMethod onClosed={onClosed} />
        </Elements>
    )
}


function UpdatePaymentMethod(props: { onClosed: () => void }) {
    const stripe = useStripe()
    const elements = useElements()
    const [showApplePay, setShowApplePay] = useState(false)
    const { ipassApiClient } = useApiClients()
    const [loading, setLoading] = useState(false)
    const appInsights = useAppInsightsContext()

    const handleCheckoutSubmit = async () => {
        if (!stripe || !elements) {
            return
        }

        const { error: submitError } = await elements.submit()
        if (submitError) {
            return
        }

        setLoading(true)
        window.scrollTo(0, 0)

        const clientSecret = await ipassApiClient.stripe.updateCard()

        const { error } = await stripe.confirmSetup({
            clientSecret: clientSecret,
            elements: elements,
            confirmParams: {
                return_url: `${window.location.protocol}//${window.location.host}/subscription`,
            },
        })

        if (error) {
            appInsights.trackException({ properties: { ...error } })
            toast.error(`Something went wrong: ${error.message}`)
        }

        setLoading(false)
    }

    return (
        <Modal onClosed={props.onClosed} isOpen={true}>
            <div className='modal-content'>
                <ModalHeader>Update payment method</ModalHeader>
                <ModalBody>
                    <PaymentElement onChange={e => setShowApplePay(e.value.type === 'apple_pay')} />
                </ModalBody>
                <ModalFooter>
                    {showApplePay
                        ? <button disabled={loading} className='apple-pay-button apple-pay-button-black apple-pay-button-update' onClick={e => { e.preventDefault(); handleCheckoutSubmit() }} ></button>
                        : <ButtonLoading type='submit' onClick={e => { e.preventDefault(); handleCheckoutSubmit() }} disabled={loading} loading={loading} className='btn btn-block btn-lg btn-primary'>Update payment method</ButtonLoading>
                    } <button type='button' className='btn btn-default' onClick={props.onClosed}>Cancel</button>
                </ModalFooter>
            </div>
        </Modal >
    )
}