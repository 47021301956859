import React from 'react';
import AdminList from './AdminList';

export default function Admins() {
    document.title = 'Admins'

    return (
        <div className='container'>
            <div className='row'>
                <AdminList />
            </div>
        </div>
    )
}

