import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import debounce from 'lodash.debounce';
import React, { useEffect, useRef, useState } from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';
import { Controller, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { AdminModel, HostedUserRepositoryOptions, UserUpdateViewModel } from '../apiclients/ipassportalapiclient';
import { isApiError, useApiClients } from '../Shared/ApiClientsContext';
import { AsyncActionState } from '../Shared/AsyncActionState';
import { ButtonLoading } from '../Shared/components';
import { useCurrentUserContext } from '../Shared/CurrentUserContext';
import { resolveErrorMessage } from '../Signup/TextInput';

type Props = {
    onClosed: (userId?: number) => void
}

export default function DeleteUserModal(props: Props) {
    document.title = 'Edit user'

    const { userid } = useParams<{ userid: string }>()
    const userId = userid ? parseInt(userid, 10) : null
    const [loading, setLoading] = useState(false)
    const { ipassApiClient } = useApiClients()
    const [admins, setAdmins] = useState<AsyncActionState<readonly AdminModel[]>>({ data: [], state: 'init' })
    const appInsights = useAppInsightsContext()

    const { register, setValue, handleSubmit, formState: { errors }, watch, control } = useForm<UserUpdateViewModel & { admin: { id: number, label: string }[] }>({
        mode: 'all', defaultValues: {}
    })

    const { fullname } = watch()
    const [hostedSettings, setHostedSettings] = useState<HostedUserRepositoryOptions | null>(null)
    const { currentUser } = useCurrentUserContext()
    const [ownerAdmin, setOwnerAdmin] = useState('')

    useEffect(() => {
        if (currentUser?.roles?.includes('GlobalAdmin')) {
            setAdmins(o => ({ ...o, state: 'pending' }))
            ipassApiClient.admins.listAdmins().then(list => {
                setAdmins({ data: list, state: 'success' })
            })
        }
    }, [ipassApiClient.admins, currentUser?.roles])

    useEffect(() => {
        if (userId) {
            document.title = 'Edit user';

            ipassApiClient.users.userDetail(userId).then(response => {
                setValue('emailAddress', response.userEmail)
                setValue('fullname', response.userFirstname + ' ' + response.userLastname)
                setValue('username', response.username + '@' + response.domain)
                setOwnerAdmin(`${response.adminEmail} (${response.stripeCustomerId})`)
            }).catch(e => {
                if (isApiError(e)) {
                    appInsights.trackException({ properties: { ...e } })
                    toast.error(`Something went wrong: ${e.message}`)
                }
            })
        } else {
            document.title = 'Add user';
            Promise.all([
                ipassApiClient.users.getRandomUsername(),
                ipassApiClient.users.getHostedSettings()
            ]).then((result) => {
                setHostedSettings(result[1])
                setValue('username', result[0])
            })
        }
    }, [ipassApiClient.users, userId, setValue, appInsights])

    const checkUsernameAvailabilityValidator = async (value: string) => ({ valid: (await ipassApiClient.users.checkAvailability(value)).available, message: 'Username already taken' });

    const checkUsernamePattern = (username: string) => ({ valid: username === '' || /^[a-zA-Z0-9-_.]+$/.test(username), message: 'Username can only contain A-Z 0-9 and -_.' })

    const validateUsername = useRef(debounce(async (value: string) => {
        const patternResult = checkUsernamePattern(value)
        if (!patternResult.valid) {
            return patternResult.message
        }

        const usernameAvailableResult = await checkUsernameAvailabilityValidator(value)
        if (!usernameAvailableResult?.valid) {
            return usernameAvailableResult?.message
        }

        return undefined
    }, 700, { leading: true })).current

    const handleSave = handleSubmit(async (formData, errors) => {
        if (!errors) {
            setLoading(true)

            const data: UserUpdateViewModel = { ...formData, adminId: (!userId && currentUser?.roles?.includes('GlobalAdmin')) ? formData.admin[0].id : undefined }

            try {
                if (userId) {
                    await ipassApiClient.users.updateUser(userId, data)
                    toast.success('The user was saved')
                    props.onClosed(userId)
                }
                else {
                    const response = await ipassApiClient.users.createUser(data)
                    toast.success(`The user was created and an invite was sent to ${data.emailAddress}`)
                    props.onClosed(response.userId);
                }
            }
            catch (e) {
                if (isApiError(e)) {
                    appInsights.trackException({ properties: { ...e } })
                    toast.error(`Something went wrong: ${e.message}`)
                }
            }

            setLoading(false)
        }
    })

    return (
        <Modal onClosed={props.onClosed} isOpen={true}>
            <div className='modal-content'>
                <form onSubmit={e => { e.preventDefault(); handleSave() }}>
                    <ModalHeader>{userId ? `Edit user ${fullname}` : 'Add user'}</ModalHeader>
                    <ModalBody>
                        <p>
                            <strong>Order and add a new user to your subscription</strong><br />
                            You can add a new user for another person or for yourself for extra data usage.<br />
                            If you are experiencing issues connecting, please contact <a href='mailto:support@flexinets.se'>support@flexinets.se</a> do not add another user.
                        </p>
                        <div className='form-group required'>
                            <label htmlFor='fullname'>Name</label>
                            <input type='text' placeholder='Firstname Lastname' className={errors.fullname ? 'form-control is-invalid' : 'form-control'} {...register('fullname', { required: true })} />
                            <div className="invalid-feedback">{resolveErrorMessage(errors.fullname)}</div>
                        </div>
                        <div className='form-group required'>
                            <label htmlFor='emailAddress'>Email</label>
                            <input type='email' placeholder='email@example.com' className={errors.emailAddress ? 'form-control is-invalid' : 'form-control'} {...register('emailAddress', { required: true })} />
                            <div className="invalid-feedback">{resolveErrorMessage(errors.emailAddress)}</div>
                        </div>

                        {currentUser?.roles?.includes('GlobalAdmin') && !userId &&
                            <Controller control={control} name='admin' rules={{ required: true }}
                                render={({ field, fieldState }) => (
                                    <div className='form-group required'>
                                        <label htmlFor='admin'>AdminId (Customer)</label>
                                        <Typeahead {...field} id='admin' className={fieldState.error ? 'is-invalid' : ''} options={admins.data.map(admin => ({ id: admin.adminId, label: `${admin.email} (${admin.stripeCustomerId})` }))}></Typeahead>
                                        <div className="invalid-feedback">{resolveErrorMessage(fieldState.error)}</div>
                                    </div>
                                )}
                            />
                        }

                        {currentUser?.roles?.includes('GlobalAdmin') && userId &&
                            <div className='form-group'>
                                <label htmlFor='username'>AdminId (Customer)</label>
                                <input type='text' className='form-control' readOnly value={ownerAdmin} />
                            </div>
                        }

                        {!userId
                            ? <div className='form-group required'>
                                <label htmlFor='fullname'>Username</label>
                                <div role="group" className="input-group">
                                    <input type='text' placeholder='Username' className={errors.username ? 'form-control is-invalid' : 'form-control'} {...register('username', { required: true, validate: validateUsername })} />
                                    <div className="input-group-append">
                                        <span className="input-group-text">@{hostedSettings?.domain}</span>
                                    </div>
                                    <div className="invalid-feedback">{resolveErrorMessage(errors.username)}</div>
                                </div>
                                <small className='form-text text-muted'>
                                    Set to desired username or use a randomly generated. <br />
                                    The username must be unique and cannot be changed later
                                </small>
                            </div>
                            : <div className='form-group required'>
                                <label htmlFor='username'>Username</label>
                                <input type='text' readOnly className={errors.username ? 'form-control is-invalid' : 'form-control'} {...register('username', { required: true })} />
                                <small className='form-text text-muted'>Use this username@domain for logging in manually if automatic client login does not work.</small>
                            </div>
                        }
                        {!userId &&
                            <div className='bs-callout bs-callout-info small'>
                                <h4>Adding a new user</h4>
                                <p><strong>Important: By adding a new user to your account you accept Flexinets terms and conditions and will be charged according to your current price plan.</strong></p>
                                <p>After adding a new user subscription an email invite for downloading the App and activating the service will be sent to the user. If needed the invite can be resent later.</p>
                            </div>
                        }
                    </ModalBody>
                    <ModalFooter>
                        <ButtonLoading className='btn btn-primary' onSubmit={e => { e.preventDefault(); handleSave() }} loading={loading} type='submit'>{userId ? 'Save user' : 'ADD NEW USER TO SUBSCRIPTION'}</ButtonLoading> <button type='button' className='btn btn-default' onClick={() => props.onClosed()}>Cancel</button>
                    </ModalFooter>
                </form>
            </div>
        </Modal >
    )
}
