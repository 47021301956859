import React, { FormEvent, useState } from 'react';
import { Link, Route, Routes, useNavigate } from 'react-router-dom';
import { Alert } from 'reactstrap';
import { ButtonLoading } from '../Shared/components';
import { useCurrentUserContext } from '../Shared/CurrentUserContext';
import BeginPasswordReset from './BeginPasswordReset';

export default function Login() {
    document.title = 'Login'

    const { login, isLoggedIn } = useCurrentUserContext()
    const navigate = useNavigate()

    const [loading, setLoading] = useState(false)
    const [alertText, setAlertText] = useState('')
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')

    if (isLoggedIn) {
        navigate('/')
    }

    const handleLogin = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setLoading(true)
        const response = await login(username, password)
        setLoading(false)
        if (response) {
            // if (location?.state?.previousLocation?.pathname) {
            //     this.props.history.push(this.props.location.state.previousLocation.pathname);
            // } else { history.push('/'); }
            // todo fix, exists in privateroute
            navigate('/')
        } else {
            setAlertText('Bad username or password')
        }
    }

    return (
        <div className='col-md-6 offset-md-3'>
            <div className='card mt-3'>
                <div className='card-body'>
                    <form method='post' onSubmit={handleLogin} className='form-signin'>
                        <h3>Log in to Flexinets Global Wi-Fi</h3>

                        <Alert color='warning' isOpen={alertText !== ''} toggle={() => { setAlertText('') }}>{alertText}</Alert>

                        <label className='sr-only' htmlFor='Username'>Email address</label>
                        <input type='email' autoComplete='username' value={username} onChange={e => setUsername(e.target.value)} id='Username' name='username' placeholder='Email address' required autoFocus className='form-control' />
                        <label className='sr-only' htmlFor='Password'>Password</label>
                        <input type='password' autoComplete='current-password' value={password} onChange={e => setPassword(e.target.value)} id='Password' name='password' placeholder='Password' required className='form-control' />

                        <div className="d-grid gap-2">
                            <ButtonLoading type='submit' loading={loading} className='btn btn-block btn-primary mt-4 mb-4'>Log in</ButtonLoading>
                            <div className='text-center'><h6><Link to='/login/reset'>Forgot password?</Link></h6></div>
                        </div>

                        {/* <div className='text-center'><h5>or <Link to='/signup'>Sign up</Link></h5></div> */}
                    </form>
                </div>
            </div>

            <Routes>
                <Route path='reset' element={<BeginPasswordReset onClosed={() => null} />} />
            </Routes>
        </div >
    )
}
