/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CheckAvailabilityResult } from '../models/CheckAvailabilityResult';
import type { CreateUserResult } from '../models/CreateUserResult';
import type { HostedUserModel } from '../models/HostedUserModel';
import type { HostedUserRepositoryOptions } from '../models/HostedUserRepositoryOptions';
import type { StatusResult } from '../models/StatusResult';
import type { UsersResponse } from '../models/UsersResponse';
import type { UserUpdateViewModel } from '../models/UserUpdateViewModel';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class UsersService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @param search
     * @param ownerAdminId
     * @returns UsersResponse Success
     * @throws ApiError
     */
    public users(
        search?: string,
        ownerAdminId?: string,
    ): CancelablePromise<UsersResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/users',
            query: {
                'search': search,
                'ownerAdminId': ownerAdminId,
            },
        });
    }

    /**
     * @param requestBody
     * @returns CreateUserResult Success
     * @throws ApiError
     */
    public createUser(
        requestBody?: UserUpdateViewModel,
    ): CancelablePromise<CreateUserResult> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/users',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param userId
     * @returns HostedUserModel Success
     * @throws ApiError
     */
    public userDetail(
        userId: number,
    ): CancelablePromise<HostedUserModel> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/users/{userId}',
            path: {
                'userId': userId,
            },
        });
    }

    /**
     * @param userId
     * @param requestBody
     * @returns number Success
     * @throws ApiError
     */
    public updateUser(
        userId: number,
        requestBody?: UserUpdateViewModel,
    ): CancelablePromise<number> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/users/{userId}',
            path: {
                'userId': userId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param userId
     * @returns StatusResult Success
     * @throws ApiError
     */
    public delete(
        userId: number,
    ): CancelablePromise<StatusResult> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/users/{userId}',
            path: {
                'userId': userId,
            },
        });
    }

    /**
     * @param requestBody
     * @returns StatusResult Success
     * @throws ApiError
     */
    public invite(
        requestBody?: Array<number>,
    ): CancelablePromise<StatusResult> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/users/sendinvite',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody
     * @returns any Success
     * @throws ApiError
     */
    public retrievePassword(
        requestBody?: Array<number>,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/users/retrievepassword',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param username
     * @returns CheckAvailabilityResult Success
     * @throws ApiError
     */
    public checkAvailability(
        username?: string,
    ): CancelablePromise<CheckAvailabilityResult> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/users/checkusernameavailability',
            query: {
                'username': username,
            },
        });
    }

    /**
     * @returns string Success
     * @throws ApiError
     */
    public getRandomUsername(): CancelablePromise<string> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/users/getrandomusername',
        });
    }

    /**
     * @returns HostedUserRepositoryOptions Success
     * @throws ApiError
     */
    public getHostedSettings(): CancelablePromise<HostedUserRepositoryOptions> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/users/settings',
        });
    }

    /**
     * @returns any Success
     * @throws ApiError
     */
    public export(): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/export/users',
        });
    }

}
